import { Menu } from '@mui/material'
import { useState } from 'react'

import { Icon } from '../../../../../equity/web/atoms/icon'
import { Pill } from '../../../../../equity/web/atoms/pill'
import { ListItem } from '../../../../../equity/web/molecules/list-item'
import { SearchSource } from '../../domain/use-global-search'

export const allSearchSources = [
  'products',
  'funds-orders',
  'portfolios',
  'sp-products',
  'sp-orders',
  'iam-firms',
  'iam-teams',
  'iam-users',
  'accounts',
  'households',
] as SearchSource[]

const visibleSearchSources = [
  'products',
  'funds-orders',
  'portfolios',
  'iam-firms',
  'iam-teams',
  'iam-users',
  'accounts',
  'households',
] as SearchSource[]

export const searchSourceNameMapping: Partial<Record<SearchSource, string>> = {
  products: 'Products',
  'funds-orders': 'Orders',
  portfolios: 'Portfolios',
  'sp-products': 'SP Products',
  'sp-orders': 'SP Orders',
  positions: 'Positions',
  'iam-users': 'Users',
  'iam-teams': 'Teams',
  'iam-firms': 'Firms',
  accounts: 'Accounts',
  households: 'Households',
}

const multiSourceMapping: Partial<Record<SearchSource, SearchSource[]>> = {
  products: ['products', 'sp-products'],
  'sp-products': ['products', 'sp-products'],
  'funds-orders': ['funds-orders', 'sp-orders'],
  'sp-orders': ['funds-orders', 'sp-orders'],
}

type Props = {
  activeSearchSources: SearchSource[]
  toggleSearchSources: (domain: SearchSource[]) => void
  isModal?: boolean
}

export const SearchDomains = ({
  activeSearchSources,
  toggleSearchSources,
  isModal,
}: Props) => {
  const [showMore, setShowMore] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <div className="flex flex-wrap items-center gap-8">
      {visibleSearchSources
        .slice(0, isModal ? 3 : visibleSearchSources.length)
        .map((source) => {
          const isActive = activeSearchSources.includes(source)
          return (
            <Pill
              state={isActive ? 'active' : 'regular'}
              key={source}
              onClick={() =>
                toggleSearchSources(
                  multiSourceMapping[source]
                    ? multiSourceMapping[source]
                    : [source]
                )
              }
              color="primary"
              size="small"
              icon={isActive ? 'Check' : undefined}
            >
              {searchSourceNameMapping[source]}
            </Pill>
          )
        })}
      {isModal ? (
        <div className="relative">
          <Pill
            state={showMore ? 'active' : 'regular'}
            onClick={(e) => {
              setShowMore((state) => !state)
              setAnchorEl(e.currentTarget)
            }}
            color="primary"
            size="small"
            icon="Add"
          >
            <div />
          </Pill>
          <Menu
            classes={{ paper: 'shadow-5', list: 'my-16 mx-0 px-16' }}
            anchorEl={anchorEl}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={showMore}
            onClose={() => setShowMore(false)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            data-testid="user-actions-menu"
          >
            <div className="flex flex-col gap-y-8">
              {visibleSearchSources
                .slice(3, visibleSearchSources.length)
                .map((source) => {
                  const isActive = activeSearchSources.includes(source)
                  return (
                    <ListItem
                      key={source}
                      onClick={() =>
                        toggleSearchSources(
                          multiSourceMapping[source]
                            ? multiSourceMapping[source]
                            : [source]
                        )
                      }
                      isSelected={isActive}
                      isInteractive
                    >
                      <div className="flex items-center gap-x-8">
                        {isActive ? (
                          <Icon
                            type="Check"
                            size="small"
                            color="eq-color-primary-900"
                          />
                        ) : null}
                        {searchSourceNameMapping[source]}
                      </div>
                    </ListItem>
                  )
                })}
            </div>
          </Menu>
        </div>
      ) : null}
    </div>
  )
}
