import { PortfolioSummaryStatsResponseV1 } from '@cais-group/portfolio-construction-tool/domain/api'

export enum StatisticLabels {
  CURRENT_MODEL = 'Current Model',
  COMPARED_MODEL = 'Compared Model',
  PREVIOUS_VERSION = 'Previous Version',
}

export enum StatisticTypes {
  HISTORICAL_ANNUALIZED_RETURN = 'HISTORICAL_ANNUALIZED_RETURN',
  'HISTORICAL_ANNUALIZED_VOLATILITY' = 'HISTORICAL_ANNUALIZED_VOLATILITY',
  'HISTORICAL_SHARPE_RATIO' = 'HISTORICAL_SHARPE_RATIO',
  'MAX_DRAWDOWN' = 'MAX_DRAWDOWN',
  FORECASTED_ANNUALIZED_RETURN = 'FORECASTED_ANNUALIZED_RETURN',
  FORECASTED_ANNUALIZED_VOLATILITY = 'FORECASTED_ANNUALIZED_VOLATILITY',
  FORECASTED_SHARPE_RATIO = 'FORECASTED_SHARPE_RATIO',
}

export const HistoricalStatisticTypes = [
  StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
  StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
  StatisticTypes.HISTORICAL_SHARPE_RATIO,
  StatisticTypes.MAX_DRAWDOWN,
]

export enum StatisticPeriods {
  FULL_HISTORY = 'FULL_HISTORY',
  ONE_YEAR = '1Y',
  THREE_YEARS = '3Y',
  FIVE_YEARS = '5Y',
}

export type HistoricalData = {
  label: string
  fullHistory: {
    thisModel: string
    previousModel?: string | undefined
    comparedModel: string
  }
  periods?: Record<
    | StatisticPeriods.ONE_YEAR
    | StatisticPeriods.THREE_YEARS
    | StatisticPeriods.FIVE_YEARS,
    {
      thisModel: string
      previousModel?: string | undefined
      comparedModel: string
    }
  >
}[]

type HistoricalStatisticValue = {
  [StatisticPeriods.FULL_HISTORY]?: number
  [StatisticPeriods.ONE_YEAR]?: number
  [StatisticPeriods.THREE_YEARS]?: number
  [StatisticPeriods.FIVE_YEARS]?: number
}

type DataObject = Record<
  StatisticTypes,
  {
    current: HistoricalStatisticValue
    compared: HistoricalStatisticValue
    previous: HistoricalStatisticValue
  }
>

export const transformSummaryStatsResponse = (
  data: PortfolioSummaryStatsResponseV1[],
  hasPreviousVersion?: boolean
) => {
  const dataObject = data.reduce(
    (acc, model) => {
      model.stats.forEach((stat) => {
        if (model.label === StatisticLabels.CURRENT_MODEL) {
          acc[stat.name as StatisticTypes].current = {
            ...acc[stat.name as StatisticTypes].current,
            [model.analyticsDateRange.label]: stat.value,
          }
        }
        if (
          hasPreviousVersion &&
          model.label === StatisticLabels.PREVIOUS_VERSION
        ) {
          acc[stat.name as StatisticTypes].previous = {
            ...acc[stat.name as StatisticTypes].previous,
            [model.analyticsDateRange.label]: stat.value,
          }
        }
        if (model.label === StatisticLabels.COMPARED_MODEL) {
          acc[stat.name as StatisticTypes].compared = {
            ...acc[stat.name as StatisticTypes].compared,
            [model.analyticsDateRange.label]: stat.value,
          }
        }
      })

      return acc
    },
    {
      HISTORICAL_ANNUALIZED_RETURN: { current: {}, compared: {} },
      HISTORICAL_ANNUALIZED_VOLATILITY: { current: {}, compared: {} },
      HISTORICAL_SHARPE_RATIO: { current: {}, compared: {} },
      MAX_DRAWDOWN: { current: {}, compared: {} },
      FORECASTED_ANNUALIZED_RETURN: { current: {}, compared: {} },
      FORECASTED_ANNUALIZED_VOLATILITY: { current: {}, compared: {} },
      FORECASTED_SHARPE_RATIO: { current: {}, compared: {} },
    } as DataObject
  )

  const forwardLookingData = [
    {
      label: 'Annualized Return',
      thisModel: formatValue(
        dataObject,
        StatisticTypes.FORECASTED_ANNUALIZED_RETURN,
        'current',
        StatisticPeriods.ONE_YEAR
      ),
      ...(hasPreviousVersion
        ? {
            previousModel: formatValue(
              dataObject,
              StatisticTypes.FORECASTED_ANNUALIZED_RETURN,
              'previous',
              StatisticPeriods.ONE_YEAR
            ),
          }
        : {}),
      comparedModel: formatValue(
        dataObject,
        StatisticTypes.FORECASTED_ANNUALIZED_RETURN,
        'compared',
        StatisticPeriods.ONE_YEAR
      ),
    },
    {
      label: 'Annualized Volatility',
      thisModel: formatValue(
        dataObject,
        StatisticTypes.FORECASTED_ANNUALIZED_VOLATILITY,
        'current',
        StatisticPeriods.ONE_YEAR
      ),
      ...(hasPreviousVersion
        ? {
            previousModel: formatValue(
              dataObject,
              StatisticTypes.FORECASTED_ANNUALIZED_VOLATILITY,
              'previous',
              StatisticPeriods.ONE_YEAR
            ),
          }
        : {}),
      comparedModel: formatValue(
        dataObject,
        StatisticTypes.FORECASTED_ANNUALIZED_VOLATILITY,
        'compared',
        StatisticPeriods.ONE_YEAR
      ),
    },
    {
      label: 'Forecasted Sharpe Ratio',
      thisModel: formatValue(
        dataObject,
        StatisticTypes.FORECASTED_SHARPE_RATIO,
        'current',
        StatisticPeriods.ONE_YEAR
      ),
      ...(hasPreviousVersion
        ? {
            previousModel: formatValue(
              dataObject,
              StatisticTypes.FORECASTED_SHARPE_RATIO,
              'previous',
              StatisticPeriods.ONE_YEAR
            ),
          }
        : {}),
      comparedModel: formatValue(
        dataObject,
        StatisticTypes.FORECASTED_SHARPE_RATIO,
        'compared',
        StatisticPeriods.ONE_YEAR
      ),
    },
  ]

  const historicalData: HistoricalData = [
    {
      label: 'Annualized Return',
      fullHistory: {
        thisModel: formatValue(
          dataObject,
          StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
          'current',
          StatisticPeriods.FULL_HISTORY
        ),
        ...(hasPreviousVersion
          ? {
              previousModel: formatValue(
                dataObject,
                StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
                'previous',
                StatisticPeriods.FULL_HISTORY
              ),
            }
          : {}),
        comparedModel: formatValue(
          dataObject,
          StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
          'compared',
          StatisticPeriods.FULL_HISTORY
        ),
      },
      periods: {
        [StatisticPeriods.ONE_YEAR]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
            'current',
            StatisticPeriods.ONE_YEAR
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
                  'previous',
                  StatisticPeriods.ONE_YEAR
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
            'compared',
            StatisticPeriods.ONE_YEAR
          ),
        },
        [StatisticPeriods.THREE_YEARS]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
            'current',
            StatisticPeriods.THREE_YEARS
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
                  'previous',
                  StatisticPeriods.THREE_YEARS
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
            'compared',
            StatisticPeriods.THREE_YEARS
          ),
        },
        [StatisticPeriods.FIVE_YEARS]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
            'current',
            StatisticPeriods.FIVE_YEARS
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
                  'previous',
                  StatisticPeriods.FIVE_YEARS
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_RETURN,
            'compared',
            StatisticPeriods.FIVE_YEARS
          ),
        },
      },
    },
    {
      label: 'Annualized Volatility',
      fullHistory: {
        thisModel: formatValue(
          dataObject,
          StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
          'current',
          StatisticPeriods.FULL_HISTORY
        ),
        ...(hasPreviousVersion
          ? {
              previousModel: formatValue(
                dataObject,
                StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
                'previous',
                StatisticPeriods.FULL_HISTORY
              ),
            }
          : {}),
        comparedModel: formatValue(
          dataObject,
          StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
          'compared',
          StatisticPeriods.FULL_HISTORY
        ),
      },
      periods: {
        [StatisticPeriods.ONE_YEAR]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
            'current',
            StatisticPeriods.ONE_YEAR
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
                  'previous',
                  StatisticPeriods.ONE_YEAR
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
            'compared',
            StatisticPeriods.ONE_YEAR
          ),
        },
        [StatisticPeriods.THREE_YEARS]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
            'current',
            StatisticPeriods.THREE_YEARS
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
                  'previous',
                  StatisticPeriods.THREE_YEARS
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
            'compared',
            StatisticPeriods.THREE_YEARS
          ),
        },
        [StatisticPeriods.FIVE_YEARS]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
            'current',
            StatisticPeriods.FIVE_YEARS
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
                  'previous',
                  StatisticPeriods.FIVE_YEARS
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_ANNUALIZED_VOLATILITY,
            'compared',
            StatisticPeriods.FIVE_YEARS
          ),
        },
      },
    },
    {
      label: 'Sharpe Ratio (Total Analysis Period)',
      fullHistory: {
        thisModel: formatValue(
          dataObject,
          StatisticTypes.HISTORICAL_SHARPE_RATIO,
          'current',
          StatisticPeriods.FULL_HISTORY
        ),
        ...(hasPreviousVersion
          ? {
              previousModel: formatValue(
                dataObject,
                StatisticTypes.HISTORICAL_SHARPE_RATIO,
                'previous',
                StatisticPeriods.FULL_HISTORY
              ),
            }
          : {}),
        comparedModel: formatValue(
          dataObject,
          StatisticTypes.HISTORICAL_SHARPE_RATIO,
          'compared',
          StatisticPeriods.FULL_HISTORY
        ),
      },
      periods: {
        [StatisticPeriods.ONE_YEAR]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_SHARPE_RATIO,
            'current',
            StatisticPeriods.ONE_YEAR
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_SHARPE_RATIO,
                  'previous',
                  StatisticPeriods.ONE_YEAR
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_SHARPE_RATIO,
            'compared',
            StatisticPeriods.ONE_YEAR
          ),
        },
        [StatisticPeriods.THREE_YEARS]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_SHARPE_RATIO,
            'current',
            StatisticPeriods.THREE_YEARS
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_SHARPE_RATIO,
                  'previous',
                  StatisticPeriods.THREE_YEARS
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_SHARPE_RATIO,
            'compared',
            StatisticPeriods.THREE_YEARS
          ),
        },
        [StatisticPeriods.FIVE_YEARS]: {
          thisModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_SHARPE_RATIO,
            'current',
            StatisticPeriods.FIVE_YEARS
          ),
          ...(hasPreviousVersion
            ? {
                previousModel: formatValue(
                  dataObject,
                  StatisticTypes.HISTORICAL_SHARPE_RATIO,
                  'previous',
                  StatisticPeriods.FIVE_YEARS
                ),
              }
            : {}),
          comparedModel: formatValue(
            dataObject,
            StatisticTypes.HISTORICAL_SHARPE_RATIO,
            'compared',
            StatisticPeriods.FIVE_YEARS
          ),
        },
      },
    },
    {
      label: 'Maximum Drawdown (Total Analysis Period)',
      fullHistory: {
        thisModel: formatValue(
          dataObject,
          StatisticTypes.MAX_DRAWDOWN,
          'current',
          StatisticPeriods.FULL_HISTORY
        ),
        ...(hasPreviousVersion
          ? {
              previousModel: formatValue(
                dataObject,
                StatisticTypes.MAX_DRAWDOWN,
                'previous',
                StatisticPeriods.FULL_HISTORY
              ),
            }
          : {}),
        comparedModel: formatValue(
          dataObject,
          StatisticTypes.MAX_DRAWDOWN,
          'compared',
          StatisticPeriods.FULL_HISTORY
        ),
      },
    },
  ]

  return { forwardLookingData, historicalData }
}

export const formatValue = (
  dataObject: DataObject,
  type: StatisticTypes,
  key: 'current' | 'compared' | 'previous',
  period: StatisticPeriods
) => {
  const value = dataObject?.[type]?.[key]?.[period]
  if (typeof value === 'undefined') return '-'
  if (
    type === StatisticTypes.HISTORICAL_SHARPE_RATIO ||
    type === StatisticTypes.FORECASTED_SHARPE_RATIO
  ) {
    return value.toFixed(2)
  }
  return (value * 100).toFixed(1) + '%'
}
