import { LegendOrdinal } from '@visx/legend'
import { scaleOrdinal } from '@visx/scale'
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  Tooltip,
  XYChart,
  buildChartTheme,
} from '@visx/xychart'
import dayjs from 'dayjs'

import { colors } from '../../../../../../../equity/particles/colors'

// eslint-disable-next-line @nx/enforce-module-boundaries

const CHART_HEIGHT = 512

type Data = {
  x: string
  y: number
}

const accessors = {
  xAccessor: (d: Data) => d.x,
  yAccessor: (d: Data) => d.y,
}

const chartTheme = buildChartTheme({
  backgroundColor: colors['eq-color-neutral-0'],
  colors: [colors['eq-color-datavis-1'], colors['eq-color-datavis-2']],
  gridColor: colors['eq-color-neutral-200'],
  gridColorDark: colors['eq-color-neutral-800'],
  tickLength: 8,
})

const loadingChartTheme = buildChartTheme({
  backgroundColor: colors['eq-color-neutral-0'],
  colors: [colors['eq-color-neutral-100']],
  gridColor: colors['eq-color-neutral-200'],
  gridColorDark: colors['eq-color-neutral-800'],
  tickLength: 8,
})

const loadingDataSets = [
  {
    dataKey: '1',
    data: [
      { x: '2007-12-31', y: 9 },
      { x: '2008-03-31', y: 24 },
      { x: '2008-06-30', y: 13.3 },
      { x: '2008-09-30', y: 33 },
    ],
  },
]

export const LineChart = ({
  dataSets,
  yAxisTickFormatter = (value) => value.toString(),
  xAxisTickFormatter = (value) => value.toString(),
  tooltipValueFormatter = (value) => value.toString(),
  isLoading,
}: {
  dataSets: { dataKey: string; data: Data[] }[]
  yAxisTickFormatter?: (value: number) => string
  xAxisTickFormatter?: (value: number) => string
  tooltipValueFormatter?: (value: number) => string
  isLoading?: boolean
}) => (
  <div className="w-full pb-32" style={{ height: CHART_HEIGHT }}>
    <XYChart
      height={CHART_HEIGHT}
      theme={isLoading ? loadingChartTheme : chartTheme}
      xScale={{ type: 'band', ...(isLoading ? { paddingOuter: 0.5 } : {}) }}
      yScale={{
        type: 'linear',
        ...(isLoading ? { domain: [0, 40] } : {}),
      }}
      margin={{ bottom: 80, top: 32, left: 50, right: 50 }}
    >
      {isLoading ? null : (
        <AnimatedAxis
          tickFormat={xAxisTickFormatter}
          orientation="bottom"
          labelOffset={20}
          hideTicks
          hideAxisLine
        />
      )}
      {isLoading ? null : (
        <AnimatedAxis tickFormat={yAxisTickFormatter} orientation="left" />
      )}
      <AnimatedGrid columns={false} numTicks={4} />
      {(isLoading ? loadingDataSets : dataSets).map((dataSet) => (
        <AnimatedLineSeries
          key={dataSet.dataKey}
          dataKey={dataSet.dataKey}
          data={dataSet.data}
          className={isLoading ? 'animate-pulse' : undefined}
          {...accessors}
          {...(isLoading ? { strokeWidth: 12 } : {})}
        />
      ))}
      {!isLoading ? (
        <Tooltip<Data>
          unstyled
          showVerticalCrosshair
          showSeriesGlyphs
          applyPositionStyle
          renderTooltip={({ tooltipData, colorScale }) => {
            return tooltipData && tooltipData?.datumByKey ? (
              <div className="mx-0 w-[250px] w-fit bg-neutral-900 px-16 py-8">
                <div className="flex flex-col gap-y-12">
                  <div className="text-neutral-0 small-strong">
                    {dayjs(tooltipData?.nearestDatum?.datum.x).format(
                      'MMM D, YYYY'
                    )}
                  </div>
                  {Object.entries(tooltipData?.datumByKey)?.map(
                    ([key, value]) => {
                      return (
                        <div className="flex items-center gap-x-16" key={key}>
                          <span
                            style={{ background: colorScale?.(key) }}
                            className="max-h-16 min-h-16 min-w-16 max-w-16"
                          />
                          <div>
                            <div className="small-strong text-neutral-0">
                              {key}
                            </div>
                            <div className="small text-neutral-300">
                              {tooltipValueFormatter(
                                accessors.yAccessor(value.datum)
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            ) : null
          }}
        />
      ) : null}
    </XYChart>

    {!isLoading ? (
      <ChartLegend dataSets={dataSets} />
    ) : (
      // Needed so the size of the loading state doesn't change and causes blinks
      <div className="h-[24px] w-full" />
    )}
  </div>
)

const ChartLegend = ({
  dataSets,
}: {
  dataSets: { dataKey: string; data: Data[] }[]
}) => {
  const color = scaleOrdinal<string, string>({
    domain: dataSets.map((dataSet) => dataSet.dataKey),
    range: [colors['eq-color-datavis-1'], colors['eq-color-datavis-2']],
  })
  return (
    <LegendOrdinal
      direction="row"
      scale={color}
      shape="rect"
      className="small flex gap-x-32 text-neutral-600"
      style={{
        display: 'flex', // required in addition to `direction` if overriding styles
        justifyContent: 'center',
        alignContent: 'center',
      }}
    />
  )
}
