import { useNavigate } from 'react-router-dom'

import { FirmV1 } from '@cais-group/access-manager/domain/api'

import { Icon } from '../../../../../../../equity/web/atoms/icon'
import { SearchResult } from '../search-result'

const VIEW_FIRM_ROUTE = (id: string) => `/access-manager/firms/${id}`

type Props = {
  firms: FirmV1[]
  searchText?: string
}

export const Firms = ({ firms, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-y-8">
      <div className="small-strong">{firms.length} Firms</div>
      {firms.slice(0, 5).map((firm) => {
        return (
          <SearchResult
            key={firm.id}
            label={firm.name}
            actions={[
              {
                text: 'View Firm',
                handler: () => navigate(VIEW_FIRM_ROUTE(firm.id)),
              },
            ]}
            highlight={searchText}
            type="Firm"
            renderAvatar={() => (
              <Icon type="Firm" color="eq-color-neutral-400" />
            )}
          />
        )
      })}
    </div>
  )
}
