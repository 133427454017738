import { useNavigate } from 'react-router-dom'

import {
  FundProductV1,
  FundStrategy,
  InvestorLevel,
} from '@cais-group/funds-pre-trade/domain/api'
import { StructuredProductV1 } from '@cais-group/shared/domain/product-master-api'

import { formatNumberToCurrency } from '../../../../../../../equity/utils/utilitarian'
import { Button } from '../../../../../../../equity/web/atoms/button'
import { StatusTag } from '../../../../../../../equity/web/atoms/status-tag'
import { HighlightedText } from '../../../../../../../equity/web/molecules/list-item'
import { useStrategyAssetClassMapping } from '../../../../../../domain/portfolio-construction-tool'
import { FundManagerLogo } from '../../../../../../ui/fund-manager-logo'
import { ImageOrAcronym } from '../../../../../../ui/image-or-acronym'
import {
  FUND_INVESTOR_LEVEL_MAPPING,
  FUND_OBJECTIVE_MAPPING,
} from '../../../../../../util/funds-enums-formatter'

// confusingly our logoId includes the file extension i.e. .png
export function spIssuerLogoURL(
  logoId: string | null | undefined,
  size: 'thumbnail' | 'large'
): string | null | undefined {
  if (!logoId) return null

  switch (size) {
    case 'thumbnail':
      return `/structured-investments/assets/issuer-logos/thumbnails/${logoId}`
    case 'large':
      return `/structured-investments/assets/issuer-logos/${logoId}`
  }
}

const SP_SUBTYPE_MAPPING = {
  YIELD: 'Yield',
  PROTECTION: 'Protection',
  GROWTH: 'Growth',
}

const VIEW_PRODUCT_ROUTE = (id: string) => `/funds-pre-trade/funds/${id}`
const INVEST_IN_PRODUCT_ROUTE = (id: string) =>
  `/funds-trade/orders/create?fundId=${id}`

type Props = {
  products: FundProductV1[]
  spProducts: StructuredProductV1[]
  searchText?: string
}

export const Products = ({ products, spProducts, searchText }: Props) => {
  const navigate = useNavigate()
  const strategyToAssetClassMapping = useStrategyAssetClassMapping()
  return (
    <div className="flex flex-col gap-y-16">
      <div className="mb-12">Products</div>
      {products.map((product) => {
        const minInitialInvestments = (product?.shareClasses?.map(
          (sc) => sc.terms?.minimumInitialInvestment
        ) || []) as number[]

        return (
          <div
            className="flex items-center justify-between rounded-[10px] border-2 px-24 py-16"
            key={product.id}
          >
            <div className="flex items-center">
              <div className="w-[140px] min-w-[140px] pl-32">
                <FundManagerLogo
                  fundManager={
                    product?.generalInformation.fundManager?.shortName || ''
                  }
                  strategy={
                    (product.generalInformation.strategy as FundStrategy) ||
                    null
                  }
                />
              </div>

              <div className="mr-32 h-[80px] w-[1px] bg-neutral-200" />

              <div className="w-[400px]">
                {product?.generalInformation?.strategy ? (
                  <StatusTag color="success" variant="light">
                    {strategyToAssetClassMapping[
                      product?.generalInformation?.strategy
                    ] || ''}
                  </StatusTag>
                ) : null}
                <div className="mt-8">
                  <HighlightedText
                    isSearchable
                    textToHighlight={searchText}
                    inputText={product.generalInformation.legalName}
                    includeWrapper
                  />
                </div>
              </div>

              <div className="ml-88 flex items-center gap-x-56">
                <div className="flex flex-col">
                  <div className="small text-neutral-600">Objective</div>
                  <div className="small">
                    {product.objectives?.primary
                      ? FUND_OBJECTIVE_MAPPING[product.objectives?.primary]
                      : '-'}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="small text-neutral-600">Minimum</div>
                  <div className="small">
                    {minInitialInvestments.length
                      ? formatNumberToCurrency({
                          value: Math.min(...minInitialInvestments) || 0,
                        })
                      : '-'}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="small text-neutral-600">Investor level</div>
                  <div className="small">
                    {product?.terms?.investorLevel
                      ?.map(
                        (level) =>
                          FUND_INVESTOR_LEVEL_MAPPING[level as InvestorLevel]
                      )
                      .join(', ') || '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-24">
              <Button
                variant="tertiary"
                onClick={() => navigate(VIEW_PRODUCT_ROUTE(product.id))}
              >
                View Product Page
              </Button>
              <Button
                onClick={() => navigate(INVEST_IN_PRODUCT_ROUTE(product.id))}
              >
                Create Order
              </Button>
            </div>
          </div>
        )
      })}

      {spProducts.map((product) => {
        return (
          <div
            className="flex items-center justify-between rounded-[10px] border-2 px-24 py-16"
            key={product.id}
          >
            <div className="flex items-center">
              <div className="w-[140px] min-w-[140px] px-32">
                <ImageOrAcronym
                  width={68}
                  height={40}
                  acronym={product.issuer?.acronym ?? '-'}
                  src={spIssuerLogoURL(product.issuer?.logoId, 'thumbnail')}
                />
              </div>

              <div className="mr-32 h-[80px] w-[1px] bg-neutral-200" />

              <div className="w-[400px]">
                <StatusTag color="primary" variant="light">
                  Structured Note
                </StatusTag>
                <div className="mt-8">
                  <HighlightedText
                    isSearchable
                    textToHighlight={searchText}
                    inputText={product?.structure?.name?.value || ''}
                    includeWrapper
                  />
                </div>
              </div>

              <div className="ml-88 flex items-center gap-x-56">
                <div className="flex flex-col">
                  <div className="small text-neutral-600">Objective</div>
                  <div className="small">
                    {product.structure?.subType?.value
                      ? SP_SUBTYPE_MAPPING[
                          product.structure?.subType
                            ?.value as keyof typeof SP_SUBTYPE_MAPPING
                        ]
                      : '-'}
                  </div>
                </div>
              </div>
              {/*  <div className="flex flex-col">
                  <div className="small text-neutral-600">Minimum</div>
                  <div className="small">
                    {minInitialInvestments.length
                      ? formatNumberToCurrency({
                          value: Math.min(...minInitialInvestments) || 0,
                        })
                      : '-'}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="small text-neutral-600">Investor level</div>
                  <div className="small">
                    {product?.terms?.investorLevel
                      ?.map(
                        (level) =>
                          FUND_INVESTOR_LEVEL_MAPPING[level as InvestorLevel]
                      )
                      .join(', ') || '-'}
                  </div>
                </div>
              </div> */}
            </div>

            <div className="flex items-center gap-x-24">
              <Button
                variant="tertiary"
                onClick={() => navigate(VIEW_PRODUCT_ROUTE(product.id))}
              >
                View Product Page
              </Button>
              <Button
                onClick={() => navigate(INVEST_IN_PRODUCT_ROUTE(product.id))}
              >
                Create Order
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
