import { useNavigate } from 'react-router-dom'

import { AccountListItemV1 } from '../../../../../../../../../libs/shared/domain/investor-profile/src/lib/generated/api.schemas'
import { Avatar } from '../../../../../../../equity/web/atoms/avatar'
import { SearchResult } from '../search-result'

const VIEW_ACCOUNT_ROUTE = (id: string) => `/investor-profile/accounts/${id}`

type Props = {
  accounts: AccountListItemV1[]
  searchText?: string
}

export const Accounts = ({ accounts, searchText }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="mt-32 flex flex-col gap-y-8">
      <h4 className="small-strong">{accounts.length} Accounts</h4>
      {accounts.slice(0, 5).map((account) => {
        return (
          <SearchResult
            key={account.account.id}
            label={account.account.name}
            actions={[
              {
                text: 'View Accounts',
                handler: () => navigate(VIEW_ACCOUNT_ROUTE(account.account.id)),
              },
            ]}
            highlight={searchText}
            type="Account"
            renderAvatar={() => (
              <Avatar size="regular">{account.account.name}</Avatar>
            )}
          />
        )
      })}
    </div>
  )
}
