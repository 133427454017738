import cx from 'classnames'

import {
  mapTrackingKeysToDataAttributes,
  type Tracking,
} from '../../../../utils/tracking-utils'
import { Icon, IconProps } from '../../icon'

const getIconColor = (variant: IconButtonColor) => {
  const iconColorMap: Record<IconButtonColor, Array<string>> = {
    primary: [
      'enabled:text-primary-600',
      'hover:enabled:text-primary-700',
      'enabled:active:text-primary-900',
      'disabled:text-neutral-200',
    ],
    neutral: [
      'enabled:text-neutral-400',
      'enabled:hover:text-neutral-700',
      'enabled:active:text-neutral-900',
      'disabled:text-neutral-200',
    ],
    success: [
      'enabled:text-success-600',
      'enabled:hover:text-success-700',
      'enabled:active:text-success-900',
      'disabled:text-neutral-200',
    ],
    error: [
      'enabled:text-error-600',
      'enabled:hover:text-error-700',
      'enabled:active:text-error-900',
      'disabled:text-neutral-200',
    ],
    inverted: [
      'enabled:text-neutral-500',
      'enabled:hover:text-neutral-600',
      'enabled:active:text-neutral-0',
      'disabled:text-neutral-700',
    ],
  }

  return iconColorMap[variant].join(' ')
}

const getDimensions = (size: IconButtonSize) => {
  const sizeDimensionsMap: Record<IconButtonSize, string> = {
    small: 'p-[2px]', // 2px spacing is meant to be added soon to the spacing particles in the design system, once it is we can use it here instead of arbitrary one
    regular: 'p-4',
    large: 'p-4',
  }
  return sizeDimensionsMap[size]
}

export type IconButtonColor =
  | 'neutral'
  | 'success'
  | 'error'
  | 'primary'
  | 'inverted'
export type IconButtonSize = 'small' | 'regular' | 'large'

export type IconButtonProps = {
  /** Accessible description of button content */
  ariaLabel?: string
  /** The semantic color of the button */
  color?: IconButtonColor
  /** How big we want the button to be */
  size?: IconButtonSize
  /** Mandatory property to define which icon to be rendered. */
  iconType: IconProps['type']
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style' | 'className'> &
  Tracking

/**
 * The `IconButton` component is a reusable button component containing an icon.
 * It allows you to easily create interactive buttons with different semantic colors, sizes, and icons.
 *
 * The `IconButton` component can use any `icon` from the icon particles as specified by the `iconType` prop.
 * Additionally, the `IconButton` component supports general HTML button attributes provided by the `HTMLAttributes<HTMLButtonElement>` type.
 * This means you can utilize standard button attributes like `onClick`, `onFocus`, or `aria-label`.
 */
export const IconButton = ({
  color = 'primary',
  size = 'regular',
  disabled = false,
  iconType,
  onClick,
  tracking,
  ariaLabel,
  'data-testid': dataTestId,
  ...rest
}: IconButtonProps) => {
  return (
    <button
      {...rest}
      onClick={onClick}
      className={cx(
        'tr-standard-short-1-[background-color,color,opacity] flex items-center justify-center rounded-full focus-visible:outline disabled:cursor-not-allowed motion-reduce:transition-none',
        color === 'inverted'
          ? 'enabled:hover:bg-neutral-800 enabled:active:bg-neutral-900'
          : 'enabled:active:bg-neutral-0 enabled:hover:bg-neutral-100',
        getIconColor(color),
        getDimensions(size)
      )}
      disabled={disabled}
      {...mapTrackingKeysToDataAttributes(tracking)}
      data-testid={dataTestId}
      type="button"
    >
      <Icon type={iconType} size={size} />
      {ariaLabel && <span className="sr-only">{ariaLabel}</span>}
    </button>
  )
}
