import React from 'react'

import { type EnvType } from '../../types'
import { NavLink } from '../nav-link'

import * as styles from './styles'

type LogoProps = {
  env: EnvType
  logos: React.ReactNode[]
  logoHref: string
}

export function Logo(props: LogoProps) {
  const { env, logoHref, logos } = props

  return (
    <NavLink
      className={`${styles.logo} mx-auto flex scale-75 items-center gap-x-16 leading-4 !outline-0 focus-visible:ring md:scale-100 [&>*]:flex-none [&>*]:border-0`}
      env={env}
      to={logoHref}
    >
      {logos.map((logo, index) => (
        <React.Fragment key={`$logo-${index}`}>
          {index > 0 ? (
            <span className="w-[1px] self-stretch bg-neutral-200" />
          ) : null}
          {logo}
        </React.Fragment>
      ))}
    </NavLink>
  )
}
