import { useNavigate } from 'react-router-dom'

import { FirmV1 } from '@cais-group/access-manager/domain/api'

import { Icon } from '../../../../../../../equity/web/atoms/icon'
import { HighlightedText } from '../../../../../../../equity/web/molecules/list-item'

const VIEW_FIRM_ROUTE = (id: string) => `/access-manager/firms/${id}`

type Props = {
  firms: FirmV1[]
  searchText?: string
}

export const Firms = ({ firms, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col">
      <div className="mb-12">Firms</div>

      <div className="grid grid-cols-4 gap-24 [@media(min-width:2080px)]:grid-cols-5">
        {firms.map((firm) => {
          return (
            <div
              key={firm.id}
              className="hover:shadow-2 flex cursor-pointer items-center gap-x-8 border-2 px-24 py-16"
              onClick={() => navigate(VIEW_FIRM_ROUTE(firm.id))}
            >
              <div className="flex size-[42px] items-center justify-center rounded-full border-2 bg-neutral-100">
                <Icon type="Firm" color="eq-color-neutral-400" />
              </div>
              <div className="body-strong">
                <HighlightedText
                  isSearchable
                  textToHighlight={searchText}
                  inputText={firm.name}
                  includeWrapper
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
