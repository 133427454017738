import cx from 'classnames'

import { StatusTag } from '../../../../../../../equity/web/atoms/status-tag'
import { HighlightedText } from '../../../../../../../equity/web/molecules/list-item'
import {
  ContextMenuTable,
  MenuTableActions,
} from '../../../../../../ui/buttons/lib/context-menu-table'

type Props = {
  label: string
  type: string
  actions?: MenuTableActions
  renderAvatar?: () => React.ReactNode
  avatarType?: 'rounded' | 'square'
  highlight?: string
  onClick?: () => void
}

export const SearchResult = ({
  label,
  type,
  actions,
  renderAvatar,
  avatarType = 'rounded',
  highlight,
  onClick,
}: Props) => {
  return (
    <div
      className={cx('curs flex w-full items-center justify-between py-8', {
        'cursor-pointer hover:bg-neutral-100': !!onClick,
      })}
      onClick={onClick}
    >
      <div className="flex items-center  gap-x-16">
        <div
          className={cx(
            'border-1 flex size-[34px] items-center justify-center overflow-hidden border-neutral-200 bg-neutral-100',
            {
              'rounded-full': avatarType === 'rounded',
              '': avatarType === 'square',
            }
          )}
        >
          {renderAvatar?.()}
        </div>

        <div className="small">
          <HighlightedText
            isSearchable
            textToHighlight={highlight}
            inputText={label}
            includeWrapper
          />
        </div>
      </div>

      <div className="flex items-center gap-x-16">
        <StatusTag variant="light">{type}</StatusTag>

        {actions ? (
          <div className="flex gap-x-8">
            <ContextMenuTable actions={actions} />
          </div>
        ) : null}
      </div>
    </div>
  )
}
