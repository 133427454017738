import { useNavigate } from 'react-router-dom'

import { TeamV1 } from '@cais-group/access-manager/domain/api'

import { Icon } from '../../../../../../../equity/web/atoms/icon'
import { SearchResult } from '../search-result'

const VIEW_TEAM_ROUTE = (firmId: string, teamId: string) =>
  `/access-manager/firms/${firmId}/teams/${teamId}`

type Props = {
  teams: TeamV1[]
  searchText?: string
}

export const Teams = ({ teams, searchText }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-y-8">
      <div className="small-strong">{teams.length} Teams</div>
      {teams.slice(0, 5).map((team) => {
        return (
          <SearchResult
            key={team.id}
            label={team.name}
            actions={[
              {
                text: 'View Team',
                handler: () =>
                  navigate(VIEW_TEAM_ROUTE(team?.firmId || '', team.id)),
              },
            ]}
            type="Team"
            highlight={searchText}
            renderAvatar={() => (
              <Icon type="Teams" color="eq-color-neutral-400" />
            )}
          />
        )
      })}
    </div>
  )
}
