import { motion } from 'framer-motion'

import { Button } from '../../../../../equity/web/atoms/button'
import { Icon } from '../../../../../equity/web/atoms/icon'
import { LoadingIndicator } from '../../../../../equity/web/atoms/loading-indicator'
import { TextInput } from '../../../../../equity/web/atoms/text-input'
import { SearchSource, useGlobalSearch } from '../../domain/use-global-search'
import { SearchDomains } from '../search-domains'

import { Accounts } from './components/accounts'
import { AISearch } from './components/ai-search'
import { Firms } from './components/firms'
import { Households } from './components/households'
import { Orders } from './components/orders'
import { Portfolios } from './components/portfolios'
import { Products } from './components/products'
import { Teams } from './components/teams'
import { Users } from './components/users'

type Props = {
  searchText: string
  updateSearchText: (text: string) => void
  data: ReturnType<typeof useGlobalSearch>
  onClose: () => void
  activeSearchSources: SearchSource[]
  toggleSearchSources: (sources: SearchSource[]) => void
}

export const ExpandedView = ({
  searchText,
  data,
  onClose,
  activeSearchSources,
  toggleSearchSources,
  updateSearchText,
}: Props) => {
  const { results, isLoading, isFetching } = data
  const {
    products,
    'sp-products': spProducts,
    'funds-orders': orders,
    'sp-orders': spOrders,
    portfolios,
    'iam-firms': firms,
    'iam-teams': teams,
    'iam-users': users,
    households,
    accounts,
  } = results

  return (
    <div className="shadow-6 duration-short-2 pb-88 bg-neutral-0 flex w-full grow flex-col px-56 py-32 pt-8 pt-[120px] transition-all">
      <div className="shadow-1 bg-neutral-0 fixed left-0 right-0 top-0 z-50 flex h-[theme(constants.mainMenu.height)px] items-center px-56">
        <div className="flex w-full justify-end gap-x-32">
          <div className="w-[400px] max-w-[400px]">
            <TextInput
              placeholder="Search for anything"
              value={searchText}
              onChange={(e) => updateSearchText(e.target.value || '')}
              autoFocus
              startAdornment={{
                variant: 'icon',
                icon: 'Search',
                color: 'eq-color-neutral-900',
              }}
              endAdornment={
                searchText
                  ? {
                      variant: 'icon',
                      icon: 'Close',
                      color: 'eq-color-neutral-400',
                      onClick: () => updateSearchText(''),
                    }
                  : undefined
              }
            />
          </div>
          <Button
            startAdornment="Close"
            variant="secondary"
            color="neutral"
            onClick={onClose}
          >
            Close search
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between gap-x-8">
        <div className="flex items-center gap-x-8">
          <Icon type="Search" color="eq-color-neutral-400" size="large" />
          <h3 className="headline-m-strong text-neutral-400">
            Search results for{' '}
            <span className="text-neutral-900">{searchText}</span>
          </h3>

          {isLoading || isFetching ? (
            <div className="ml-8 flex justify-center">
              <LoadingIndicator size="small" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-16 flex items-center gap-x-8">
        <SearchDomains
          activeSearchSources={activeSearchSources}
          toggleSearchSources={toggleSearchSources}
        />
      </div>
      <div className="flex flex-col gap-y-56 pt-56">
        <AISearch searchText={searchText} />

        {products?.data?.length || spProducts?.data?.length ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Products
              products={products.data || []}
              spProducts={spProducts.data || []}
              searchText={searchText}
            />
          </motion.div>
        ) : null}

        {orders?.data?.length || spOrders?.data?.length ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Orders
              orders={orders?.data || []}
              spOrders={spOrders?.data || []}
              searchText={searchText}
            />
          </motion.div>
        ) : null}
        {portfolios?.data?.length ? (
          <motion.div
            id="portfolios"
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Portfolios
              portfolios={portfolios?.data || []}
              closeSearch={onClose}
              searchText={searchText}
            />
          </motion.div>
        ) : null}
        {accounts?.data?.length ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Accounts accounts={accounts?.data || []} searchText={searchText} />
          </motion.div>
        ) : null}
        {households?.data?.length ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Households
              households={households?.data || []}
              searchText={searchText}
            />
          </motion.div>
        ) : null}
        {firms?.data?.length ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Firms firms={firms?.data || []} searchText={searchText} />
          </motion.div>
        ) : null}
        {teams?.data?.length ? (
          <motion.div
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Teams teams={teams?.data || []} searchText={searchText} />
          </motion.div>
        ) : null}
        {users?.data?.length ? (
          <motion.div
            id="users"
            className="w-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Users users={users?.data || []} searchText={searchText} />
          </motion.div>
        ) : null}
      </div>
    </div>
  )
}
