import { Components, PaletteColorOptions } from '@mui/material'

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    black: true
    neutral: true
  }
  interface PaletteOptions {
    neutral: PaletteColorOptions
    black: PaletteColorOptions
  }
}

export const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      padding: 'var(--s8) var(--s24)',
      borderRadius: 40,
    },
    label: {
      fontWeight: 600,
      fontSize: 14,
      padding: 0,
    },
    icon: {
      margin: 0,
      marginRight: '4px',
      color: 'rgb(var(--colors-neutral-900))',
      width: 20,
      height: 20,
      marginLeft: '-6px',
    },
    deleteIcon: {
      margin: 0,
      marginLeft: 'var(--s8)',
      width: 18,
      height: 18,
      marginRight: '-6px',
      color: 'rgb(var(--colors-neutral-900))',
    },
  },
  variants: [
    {
      props: {
        variant: 'outlined',
        color: 'default',
      },
      style: {
        borderColor: 'rgb(var(--colors-neutral-300))',
      },
    },
  ],
}
