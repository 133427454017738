import React from 'react'

import { ListItem } from '../../../../../../equity/web/molecules/list-item'
import { type EnvType, type MenuGroup, type NavItemLink } from '../../types'
import { NavLink } from '../nav-link'

import * as styles from './styles'

type SubMenuProps = {
  env: EnvType
  group: MenuGroup
  index: number
  subMenuId: string
  enablePendo?: boolean
  handleClick?: (href: NavItemLink['href']) => void
  trackMenuLabel?: string
}

export function SubMenu(props: SubMenuProps) {
  const { env, enablePendo, group, index, subMenuId, trackMenuLabel } = props
  return (
    <React.Fragment key={`group-${index}`}>
      {group.name && (
        <p className={styles.menuItems.caption} id={`group-${index}`}>
          {group.name}
        </p>
      )}
      <ul aria-labelledby={`group-${index}`} className="list-none">
        {group.items.map((item) => {
          return (
            <ListItem
              key={item.href}
              handleFocusWithin
              {...(enablePendo && {
                'data-pendo': `${subMenuId}-${item.name
                  .replace(/\s+/g, '-')
                  .toLowerCase()}-item`,
              })}
              data-link-id={item.id}
            >
              <NavLink
                // Note: using "after" because ListItem includes padding and want to ensure the full space is clickable
                className="relative w-full text-inherit [font-weight:inherit] after:absolute after:-inset-8 after:content-['']"
                env={env}
                data-track-item_name={
                  trackMenuLabel
                    ? `${trackMenuLabel} - ${item.name}`
                    : item.name
                }
                onLogout={item.onClick}
                to={item.href}
                urlType={item.urlType}
              >
                {item.name}
              </NavLink>
            </ListItem>
          )
        })}
      </ul>
    </React.Fragment>
  )
}
