/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Homepage BFF API
 * The Homepage BFF (Backend For Frontend) API provides endpoints to proxy and retrieve and transform content for the UI. It acts as an intermediary between the frontend and various backend services(i.e. Contentful).
 * OpenAPI spec version: 1.0.0
 */
import type {
  AnnouncementDto,
  AnnouncementsBody,
  ArticleBody,
  ArticleDto,
  ArticlesDto,
  ArticlesMetadata,
  DisclaimerDto,
  FaqsCategoriesDto,
  FaqsDto,
  GetArticleParams,
  GetArticlesParams,
  GetDisclaimerParams,
  GetFAQsByCategoryParams,
  GetFAQsCategoriesParams,
  GetFaqsBySearchParams,
  GetGlossaryParams,
  GetHomepageLandingPageParams,
  GetLegalPageBySlugParams,
  GetPageResourceParams,
  GetTutorialsParams,
  GetVideoParams,
  GetWebinarsParams,
  GetWhitepaperBySlugParams,
  GlossaryResponseDto,
  HomepageLandingPageDto,
  HomepageLandingPageRequestBody,
  LegalPageDto,
  PageResourceBody,
  PageResourceDto,
  TutorialsDto,
  TutorialsRequestBody,
  VideoDto,
  VideoRequestBody,
  WebinarsDto,
  WebinarsRequestBody,
  WhitepaperDto,
  WhitepaperRequestBody,
} from './api.schemas'
import { fetchInstanceContentful } from '../lib/shared-domain-contentful-api'

/**
 * Retrieves the whitepaper data
Provide the page slug
 */
export const getWhitepaperBySlug = (
  slug: string,
  whitepaperRequestBody: WhitepaperRequestBody,
  params?: GetWhitepaperBySlugParams
) => {
  return fetchInstanceContentful<WhitepaperDto>({
    url: `/whitepapers/${slug}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: whitepaperRequestBody,
    params,
  })
}

/**
 * Get webinars data with optional search filter
 */
export const getWebinars = (
  webinarsRequestBody: WebinarsRequestBody,
  params: GetWebinarsParams
) => {
  return fetchInstanceContentful<WebinarsDto>({
    url: `/webinars`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: webinarsRequestBody,
    params,
  })
}

/**
 * Retrieves the video data
Provide the video id
 */
export const getVideo = (
  id: string,
  videoRequestBody: VideoRequestBody,
  params?: GetVideoParams
) => {
  return fetchInstanceContentful<VideoDto>({
    url: `/videos/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: videoRequestBody,
    params,
  })
}

/**
 * Get tutorials data with optional search filter
 */
export const getTutorials = (
  tutorialsRequestBody: TutorialsRequestBody,
  params?: GetTutorialsParams
) => {
  return fetchInstanceContentful<TutorialsDto>({
    url: `/tuturials`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: tutorialsRequestBody,
    params,
  })
}

/**
 * Retrieves the resource page data
Provide the page slug
 */
export const getPageResource = (
  parentSlug: string,
  childSlug: string,
  pageResourceBody: PageResourceBody,
  params?: GetPageResourceParams
) => {
  return fetchInstanceContentful<PageResourceDto>({
    url: `/resources/${parentSlug}/${childSlug}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: pageResourceBody,
    params,
  })
}

/**
 * Retrieves the legal page data
Provide the page slug
 */
export const getLegalPageBySlug = (
  slug: string,
  params?: GetLegalPageBySlugParams
) => {
  return fetchInstanceContentful<LegalPageDto>({
    url: `/legal/${slug}`,
    method: 'GET',
    params,
  })
}

/**
 * Retrieves a landing page of content sections.
Provide the page slug
 */
export const getHomepageLandingPage = (
  homepageLandingPageRequestBody: HomepageLandingPageRequestBody,
  params: GetHomepageLandingPageParams
) => {
  return fetchInstanceContentful<HomepageLandingPageDto>({
    url: `/landing-page`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: homepageLandingPageRequestBody,
    params,
  })
}

/**
 * Retrieves a list of glossary terms.
 */
export const getGlossary = (params?: GetGlossaryParams) => {
  return fetchInstanceContentful<GlossaryResponseDto>({
    url: `/glossary`,
    method: 'GET',
    params,
  })
}

/**
 * Retrieves the faq categories data
 */
export const getFAQsCategories = (params?: GetFAQsCategoriesParams) => {
  return fetchInstanceContentful<FaqsCategoriesDto>({
    url: `/faqs/categories`,
    method: 'GET',
    params,
  })
}

/**
 * Retrieves the faqs data
 */
export const getFaqsBySearch = (params?: GetFaqsBySearchParams) => {
  return fetchInstanceContentful<FaqsDto>({
    url: `/faqs/search`,
    method: 'GET',
    params,
  })
}

/**
 * Retrieves the faqs data
 */
export const getFAQsByCategory = (params: GetFAQsByCategoryParams) => {
  return fetchInstanceContentful<FaqsDto>({
    url: `/faqs/category`,
    method: 'GET',
    params,
  })
}

/**
 * Retrieves the disclaimer data
Provide the disclaimer id
 */
export const getDisclaimer = (id: string, params?: GetDisclaimerParams) => {
  return fetchInstanceContentful<DisclaimerDto>({
    url: `/disclaimers/${id}`,
    method: 'GET',
    params,
  })
}

/**
 * Retrieves the list of articles the user has access to
 */
export const getArticles = (
  articlesMetadata: ArticlesMetadata,
  params?: GetArticlesParams
) => {
  return fetchInstanceContentful<ArticlesDto>({
    url: `/articles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: articlesMetadata,
    params,
  })
}

/**
 * Retrieves the article data
Provide the page slug
 */
export const getArticle = (
  slug: string,
  articleBody: ArticleBody,
  params?: GetArticleParams
) => {
  return fetchInstanceContentful<ArticleDto>({
    url: `/articles/${slug}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: articleBody,
    params,
  })
}

/**
 * Retrieves the details of the current announcement for the user.
Supply the announcementSet ID and the current date to get the announcement.
 */
export const getAnnouncement = (
  date: string,
  id: string,
  announcementsBody: AnnouncementsBody
) => {
  return fetchInstanceContentful<AnnouncementDto>({
    url: `/announcements/${date}/${id}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: announcementsBody,
  })
}

export type GetWhitepaperBySlugResult = NonNullable<
  Awaited<ReturnType<typeof getWhitepaperBySlug>>
>
export type GetWebinarsResult = NonNullable<
  Awaited<ReturnType<typeof getWebinars>>
>
export type GetVideoResult = NonNullable<Awaited<ReturnType<typeof getVideo>>>
export type GetTutorialsResult = NonNullable<
  Awaited<ReturnType<typeof getTutorials>>
>
export type GetPageResourceResult = NonNullable<
  Awaited<ReturnType<typeof getPageResource>>
>
export type GetLegalPageBySlugResult = NonNullable<
  Awaited<ReturnType<typeof getLegalPageBySlug>>
>
export type GetHomepageLandingPageResult = NonNullable<
  Awaited<ReturnType<typeof getHomepageLandingPage>>
>
export type GetGlossaryResult = NonNullable<
  Awaited<ReturnType<typeof getGlossary>>
>
export type GetFAQsCategoriesResult = NonNullable<
  Awaited<ReturnType<typeof getFAQsCategories>>
>
export type GetFaqsBySearchResult = NonNullable<
  Awaited<ReturnType<typeof getFaqsBySearch>>
>
export type GetFAQsByCategoryResult = NonNullable<
  Awaited<ReturnType<typeof getFAQsByCategory>>
>
export type GetDisclaimerResult = NonNullable<
  Awaited<ReturnType<typeof getDisclaimer>>
>
export type GetArticlesResult = NonNullable<
  Awaited<ReturnType<typeof getArticles>>
>
export type GetArticleResult = NonNullable<
  Awaited<ReturnType<typeof getArticle>>
>
export type GetAnnouncementResult = NonNullable<
  Awaited<ReturnType<typeof getAnnouncement>>
>
