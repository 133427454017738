import { motion } from 'framer-motion'

import { Button } from '../../../../../equity/web/atoms/button'
import { LoadingIndicator } from '../../../../../equity/web/atoms/loading-indicator'
import { SearchSource, useGlobalSearch } from '../../domain/use-global-search'
import { SearchDomains } from '../search-domains'

import { Accounts } from './components/accounts'
import { Firms } from './components/firms'
import { Households } from './components/households'
import { Orders } from './components/orders'
import { Portfolios } from './components/portfolios'
import { Products } from './components/products'
import { Teams } from './components/teams'
import { Users } from './components/users'

type Props = {
  searchText: string
  data: ReturnType<typeof useGlobalSearch>
  onExpand: () => void
  onClose: () => void
  activeSearchSources: SearchSource[]
  toggleSearchSources: (sources: SearchSource[]) => void
}

export const ModalView = ({
  searchText,
  data,
  onClose,
  activeSearchSources,
  toggleSearchSources,
  onExpand,
}: Props) => {
  const { results, isLoading, isFetching, totalResults } = data

  const {
    products,
    'sp-products': spProducts,
    'funds-orders': orders,
    'sp-orders': spOrders,
    portfolios,
    'iam-firms': firms,
    'iam-teams': teams,
    'iam-users': users,
    accounts,
    households,
  } = results

  return (
    <>
      <div
        className="fixed bottom-0 left-0 right-0 top-0 z-[49]"
        onClick={onClose}
        data-testid="pill-dropdown-overlay"
      />
      <div className="bg-neutral-0 shadow-6 absolute right-0 right-0 top-full z-50 mt-8 w-[720px]">
        <div className="max-h-[calc(100vh-theme(constants.mainMenu.height)px-56px)] w-[720px] overflow-y-scroll px-32 pb-[100px] pt-32">
          <h3 className="small-strong mb-8">Search for</h3>
          <SearchDomains
            activeSearchSources={activeSearchSources}
            toggleSearchSources={toggleSearchSources}
            isModal
          />
          <div className="my-24 h-[1px] w-full bg-neutral-200" />
          <div className="flex w-full flex-col gap-y-24">
            {searchText &&
            (products?.data?.length || spProducts?.data?.length) ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Products
                  products={products?.data || []}
                  spProducts={spProducts?.data || []}
                  searchText={searchText}
                />
              </motion.div>
            ) : null}

            {searchText && (orders?.data?.length || spOrders?.data?.length) ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Orders
                  orders={orders?.data || []}
                  spOrders={spOrders?.data || []}
                  searchText={searchText}
                />
              </motion.div>
            ) : null}

            {searchText && portfolios?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Portfolios
                  portfolios={portfolios?.data || []}
                  searchText={searchText}
                />
              </motion.div>
            ) : null}

            {searchText && firms?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Firms firms={firms?.data || []} searchText={searchText} />
              </motion.div>
            ) : null}

            {searchText && teams?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Teams teams={teams?.data || []} searchText={searchText} />
              </motion.div>
            ) : null}

            {searchText && users?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Users users={users?.data || []} searchText={searchText} />
              </motion.div>
            ) : null}

            {searchText && accounts?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Accounts
                  accounts={accounts?.data || []}
                  searchText={searchText}
                />
              </motion.div>
            ) : null}

            {searchText && households?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Households
                  households={households?.data || []}
                  searchText={searchText}
                />
              </motion.div>
            ) : null}

            {isLoading || isFetching ? (
              <div className=" flex w-full justify-center">
                <LoadingIndicator />
              </div>
            ) : null}

            {searchText?.length > 2 &&
            !isLoading &&
            !isFetching &&
            !products?.data?.length &&
            !portfolios?.data?.length &&
            !spProducts?.data?.length &&
            !orders?.data?.length &&
            !spOrders?.data?.length ? (
              <motion.div
                className="w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="small text-neutral-600">No results</div>
              </motion.div>
            ) : null}
          </div>
          {searchText && totalResults > 0 ? (
            <div className="bg-neutral-0 border-t-1 absolute bottom-0 left-0 right-0 z-50 flex items-center justify-center py-16">
              <Button variant="tertiary" onClick={onExpand}>
                Show all results
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
