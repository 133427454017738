import cx from 'classnames'
import { motion } from 'framer-motion'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { getPositionsSummary } from '@cais-group/structured-products/domain/positions-api'

import { getAccount } from '../../../../../../../../../libs/shared/domain/investor-profile/src/lib/generated/api'
import { formatNumberToCurrency } from '../../../../../../../equity/utils/utilitarian'
import { Avatar } from '../../../../../../../equity/web/atoms/avatar'
import { Button } from '../../../../../../../equity/web/atoms/button'
import { LoadingIndicator } from '../../../../../../../equity/web/atoms/loading-indicator'

type AccountHoldingsProps = {
  accountId: string
  stats: [number, number, number]
  gains: number
}

export function AccountHoldings({
  accountId,
  stats,
  gains,
}: AccountHoldingsProps) {
  const navigate = useNavigate()
  const onNavigate = () => {
    navigate(`/investor-profile/accounts/${accountId}`)
  }

  const accountResponse = useQuery({
    queryKey: ['accountById', accountId],
    queryFn: () => getAccount(accountId),
  })

  const spHoldings = useQuery({
    queryKey: ['spHoldings', { accountId }],
    queryFn: () =>
      getPositionsSummary({
        filter: {
          clientAccounts: {
            accountId: { eq: accountId },
          },
        },
        groupBy: {
          structuredProduct: { structure: { name: { sumBy: 'NOTIONAL' } } },
        },
      }),
    enabled: !!accountId,
  })
  return (
    <div className="shadow-3 border-1 flex w-full flex-row border-b-neutral-200 p-32">
      {accountResponse.isLoading ? (
        <motion.div
          className="flex w-full items-center "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <LoadingIndicator />
        </motion.div>
      ) : (
        <>
          <motion.div
            className="basis-1/4 border-r border-neutral-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Avatar size="large" type="group">
              {accountResponse?.data?.name.trim().toUpperCase()}
            </Avatar>
            <h4 className="headline-s-strong mt-32">
              {accountResponse?.data?.name}
            </h4>
            <p className="text-neutral-600">
              {formatNumberToCurrency({ value: spHoldings.data?.total || 0 })}
            </p>
            <div className="mb-56">
              <div
                className="mb-12 mr-24 h-10 rounded-md"
                style={{
                  background: `linear-gradient(
                      to right,
                      var(--eq-color-datavis-1) 0%,
                      var(--eq-color-datavis-1) ${stats[0]}%,
                      var(--eq-color-datavis-2) ${stats[0]}%,
                      var(--eq-color-datavis-2) ${stats[0] + stats[1]}%,
                      var(--eq-color-datavis-3) ${stats[0] + stats[1]}%,
                      var(--eq-color-datavis-3) 100%)`,
                }}
              >
                &nbsp;
              </div>
              <ul>
                <li className="py-12">
                  <span className="bg-datavis-1 border-radius mr-12 inline-block h-16 w-16 rounded-full"></span>
                  Hedge Funds
                </li>
                <li className="py-12">
                  <span className="bg-datavis-2 mr-12 inline-block h-16 w-16 rounded-full"></span>
                  Private Markets
                </li>
                <li className="py-12">
                  <span className="bg-datavis-3 mr-12 inline-block h-16 w-16 rounded-full"></span>
                  Structured Investments
                </li>
              </ul>
            </div>
            <Button onClick={onNavigate}>View Full Profile</Button>
          </motion.div>
          <motion.div
            className="basis-3/4 pl-56"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-row gap-x-24">
              <div className="basis-1/3 bg-neutral-100 p-24">
                <h4 className="small-strong">Total initial amount</h4>
                <p className="headline-s-strong">$100,000</p>
              </div>
              <div className="basis-1/3 bg-neutral-100 p-24">
                <h4 className="small-strong">Total market value</h4>
                <p className="headline-s-strong">$100,000</p>
              </div>
              <div className="basis-1/3 bg-neutral-100 p-24">
                <h4 className="small-strong">Total gain / loss</h4>
                <p
                  className={cx('headline-s-strong', {
                    'text-success-600': gains > 0,
                    'text-neutral-600': gains === 0,
                    'text-error-600': gains < 0,
                  })}
                >
                  +8.5%
                </p>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </div>
  )
}
