import { formatNumberToCurrency } from '../../../../../equity/utils/utilitarian'

export function formatNumberToCurrencyWithAbbreviation(value: number): string {
  const sign = value < 0 ? '-' : '' // Determine if the value is negative
  const absoluteValue = Math.abs(value) // Work with the absolute value for formatting

  if (absoluteValue >= 1_000_000_000) {
    return sign + '$' + (absoluteValue / 1_000_000_000).toFixed(1) + 'b'
  } else if (absoluteValue >= 1_000_000) {
    return sign + '$' + (absoluteValue / 1_000_000).toFixed(1) + 'm'
  } else if (absoluteValue >= 1_000) {
    return sign + '$' + (absoluteValue / 1_000).toFixed(1) + 'k'
  } else {
    return formatNumberToCurrency({ value })
  }
}
