/* eslint @nx/enforce-module-boundaries: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { useMemo, useState } from 'react'

import { AccountListItemV1 } from '../../../../../../../../../libs/shared/domain/investor-profile/src/lib/generated/api.schemas'

import { AccountCard } from './accountCard'
import { AccountHoldings } from './accountHoldings'

type Props = {
  accounts: AccountListItemV1[]
  searchText?: string
}

export const Accounts = ({ accounts, searchText }: Props) => {
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >(accounts.length === 1 ? accounts[0].account.id : undefined)

  const stats = useMemo((): [number, number, number] => {
    let first = Math.floor(Math.random() * 100)
    let second = Math.floor(Math.random() * 100)
    if (first + second > 100) {
      first = Math.floor(first / 2)
      second = Math.floor(second / 2)
    }
    return [first, second, 100 - first - second]
  }, [selectedAccountId])

  const gains = useMemo(() => {
    const first = Math.floor(Math.random() * 30)
    const second = Math.floor(Math.random() * 20)

    return Math.round(first - second * 10) / 10
  }, [selectedAccountId])

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 gap-24 [@media(min-width:2080px)]:grid-cols-5">
        {accounts.length > 1 && (
          <>
            <h4 className="small-strong col-span-full">Accounts</h4>
            {accounts.slice(0, 4).map((account) => {
              return (
                <AccountCard
                  key={account.account.id}
                  account={account}
                  isSelected={selectedAccountId === account.account.id}
                  onClick={() => setSelectedAccountId(account.account.id)}
                  highlight={searchText}
                />
              )
            })}
          </>
        )}
        {selectedAccountId && (
          <div className="col-span-full">
            <AccountHoldings
              accountId={selectedAccountId}
              stats={stats}
              gains={gains}
            />
          </div>
        )}
      </div>
    </div>
  )
}
