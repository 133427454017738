import { Components } from '@mui/material'

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    medium: false
    regular: true
  }
}

export const MuiSwitch: Components['MuiSwitch'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      position: 'relative',
      display: 'inline-block',
      transition: 'opacity 100ms ease-in-out',
      width: '36px',
      height: '22px',
      padding: '0',
      borderRadius: '22px',

      '&.MuiSwitch-sizeSmall .MuiSwitch-thumb': {
        width: '12px',
        height: '12px',
      },

      '&.MuiSwitch-sizeSmall': {
        width: '24px',
        height: '16px',
        borderRadius: '16px',
      },

      '& + .MuiFormControlLabel-label': { marginLeft: 'var(--s16)' },
      '.MuiFormControlLabel-label + &': { marginRight: 'var(--s16)' },
      '&.MuiSwitch-sizeSmall + .MuiFormControlLabel-label': {
        marginLeft: 'var(--s8)',
      },
      '.MuiFormControlLabel-label + &.MuiSwitch-sizeSmall': {
        marginRight: 'var(--s8)',
      },
    },
    switchBase: {
      display: 'contents',
      cursor: 'pointer',
      '&.Mui-disabled': {
        cursor: 'not-allowed',
      },

      '&.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgb(var(--colors-primary-600))',
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgb(var(--colors-neutral-400))',
      },

      '&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgb(var(--colors-primary-300))',
      },
    },
    input: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      opacity: '0',
      zIndex: '1',
      margin: '0',
    },
    track: {
      backgroundColor: 'rgb(var(--colors-neutral-600))',
      transition: 'background-color 100ms ease-in-out',
      borderRadius: '22px',
      display: 'block',
      height: '100%',
      width: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 1,
    },
    thumb: {
      position: 'absolute',
      transition: 'left 100ms ease-in-out',
      display: 'block',
      borderRadius: '50%',
      backgroundColor: 'rgb(var(--colors-neutral-0))',
      width: '16px',
      height: '16px',
      top: '3px',
      left: '3px',

      '.Mui-checked &': {
        left: '17px',
      },

      '.MuiSwitch-sizeSmall &': {
        width: '12px',
        height: '12px',
        top: '2px',
        left: '2px',
      },

      '.MuiSwitch-sizeSmall .Mui-checked &': {
        left: '10px',
      },
    },
  },
}
