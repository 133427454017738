import { useNavigate } from 'react-router-dom'

import { FundOrderV2 } from '@cais-group/funds-trade/domain/api'
import { StructuredProductOrderExpanded } from '@cais-group/structured-products/domain/structured-product-orders-api'

import { formatNumberToCurrency } from '../../../../../../../equity/utils/utilitarian'
import { Icon } from '../../../../../../../equity/web/atoms/icon'
import { HighlightedText } from '../../../../../../../equity/web/molecules/list-item'
import { showToast } from '../../../../../../../equity/web/organisms/notifications'

// Source:
// - https://github.com/cais-group/funds-orders/blob/main/src/main/kotlin/com/cais/fundorders/domain/OrderStatus.kt
export const orderStatusLabels: Record<string, string> = {
  FUND_QUESTIONNAIRE_IN_PROGRESS: 'Transaction Questions in Progress',
  ADVISOR_REVIEW: 'Advisor Review',
  DOCUMENTS_GENERATED: 'Documents Generated',
  PENDING_SIGNATURE: 'Pending Signature',
  SIGNATURE_COMPLETED: 'eSignature Complete; Ready to Proceed',
  SIGNATURE_DECLINED: 'A relevant party declined to eSign',
  SIGNATURE_FAILED_AUTH:
    'Action Required: Relevant Party Failed DocuSign authentication method',
  SIGNATURE_FAILED_KBA_AUTH:
    'Action Required: Relevant Party Failed DocuSign KBA method',
  SIGNATURE_FAILED_SMS_AUTH:
    'Action Required: Relevant Party Failed DocuSign SMS authentication method',
  SIGNATURE_FAILED_ACCESS_CODE_AUTH:
    'Action Required: Relevant Party Failed DocuSign Access Code authentication method',
  DELIVERY_FAILED: 'eSign failed to send to relevant party',
  HOME_OFFICE_REVIEW_1: 'Home Office Review 1',
  HOME_OFFICE_REVIEW_2: 'Home Office Review 2',
  DOCUMENTS_IN_REMEDIATION: 'Documents in Remediation',
  HOME_OFFICE_REVIEW_1_RESUBMITTED: 'Home Office Review 1',
  DOCUMENTS_WITH_FUND: 'Documents being Reviewed by Fund',
  DOCUMENTS_NIGO: 'Not in Good Order',

  // Opportunity
  DOCS_RECEIVED: 'Docs Received',
  FUNDS_RECEIVED: 'Funds Received',
  READY_TO_TRADE: 'Ready To Trade',
  COMPLETED: 'Completed',
  ERROR_UNKNOWN: 'Unknown error',
  CANCELED: 'Canceled',
  OPEN: 'Open',
  CLOSED: 'Closed',
  ACTIVE: 'Active',
}

const VIEW_ORDER_ROUTE = (id: string) => `/funds-pre-trade/funds/${id}`

type Props = {
  orders: FundOrderV2[]
  spOrders: StructuredProductOrderExpanded[]
  searchText?: string
}

export const Orders = ({ orders, spOrders, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col">
      <div className="mb-12">Orders</div>

      <div className="grid grid-cols-3 gap-24 [@media(min-width:2080px)]:grid-cols-4">
        {orders.map((order) => {
          return (
            <div
              className="hover:shadow-2 flex cursor-pointer flex-col border-2 px-24 py-16"
              key={order.id}
              onClick={() => navigate(VIEW_ORDER_ROUTE(order.id))}
            >
              <div className="body-strong">
                <HighlightedText
                  isSearchable
                  textToHighlight={searchText}
                  inputText={order.fund?.legalName || ''}
                  includeWrapper
                />
              </div>
              <div className="flex items-center gap-x-4 text-neutral-600">
                <div className="small text-neutral-600">
                  {order.shareClass?.name}
                </div>
                •
                <div
                  className="small flex cursor-pointer items-center gap-x-4 text-neutral-600"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(order.caisId)

                    showToast({
                      type: 'success',
                      title: 'Copied to clipboard',
                    })
                  }}
                >
                  #{order.caisId}
                  <Icon type="Copy" size="tiny" />
                </div>
              </div>

              <div className="my-16 h-[2px] w-full bg-neutral-200" />

              <div className="border-l-4 border-neutral-200 px-16 py-8">
                {orderStatusLabels[order?.status]}
              </div>
              <div className="flex grow flex-col justify-end">
                <div className="small mt-32 text-neutral-600">
                  Notional Investment:
                  <span className="body-strong text-neutral-900">
                    {order.type === 'SUBSCRIPTION'
                      ? formatNumberToCurrency({
                          value: order?.amount?.amount || 0,
                        })
                      : 'N/A'}
                  </span>
                </div>
                <div className="small mt-4 text-neutral-600">
                  {order?.account?.custodian?.name} as Custodian
                </div>
              </div>
            </div>
          )
        })}

        {spOrders.map((order) => {
          const cusip =
            order?.product?.identifiers.find(({ type }) => type === 'CUSIP')
              ?.value || ''

          return (
            <div
              className="hover:shadow-2 flex cursor-pointer flex-col border-2  px-24 py-16"
              key={order.order.id}
              onClick={() => navigate(VIEW_ORDER_ROUTE(order.order.id))}
            >
              <div className="body-strong">{order.order.productSource}</div>
              <div className="flex items-center gap-x-4 text-neutral-600">
                <div className="small text-neutral-600">
                  {order.product.structure.structureName}
                </div>
                •
                <div
                  className="small flex cursor-pointer items-center gap-x-4 text-neutral-600"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(cusip)

                    showToast({
                      type: 'success',
                      title: 'Copied to clipboard',
                    })
                  }}
                >
                  #{cusip || ''}
                  <Icon type="Copy" size="tiny" />
                </div>
              </div>
              <div className="my-8 h-[2px] w-full bg-neutral-200" />
              <div className="flex grow flex-col justify-end">
                <div className="border-l-4 border-neutral-200 px-16 py-8">
                  {orderStatusLabels[order?.order.status]}
                </div>

                <div className="small mt-32 text-neutral-600">
                  Notional Investment:
                  <span className="body-strong text-neutral-900">
                    {formatNumberToCurrency({
                      value: order?.order?.totalNotional || 0,
                    })}
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
