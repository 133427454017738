import { useNavigate } from 'react-router-dom'

import { HouseholdResponseV1 } from '../../../../../../../../../libs/shared/domain/investor-profile/src/lib/generated/api.schemas'
import { Avatar } from '../../../../../../../equity/web/atoms/avatar'
import { SearchResult } from '../search-result'

const VIEW_HOUSEHOLD_ROUTE = (id: string) =>
  `/investor-profile/households/${id}`

type Props = {
  households: HouseholdResponseV1[]
  searchText?: string
}

export const Households = ({ households, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-y-8">
      <div className="small-strong">{households.length} Households</div>
      {households.slice(0, 5).map((household) => {
        return (
          <SearchResult
            key={household.id}
            label={household.name}
            actions={[
              {
                text: 'View Households',
                handler: () => navigate(VIEW_HOUSEHOLD_ROUTE(household.id)),
              },
            ]}
            highlight={searchText}
            type="Household"
            avatarType="square"
            renderAvatar={() => (
              <Avatar size="regular" type="group">
                {household.name}
              </Avatar>
            )}
          />
        )
      })}
    </div>
  )
}
