import { Components } from '@mui/material'

declare module '@mui/material/Tooltip' {
  interface TooltipProps {
    size?: 'small' | 'regular'
  }
}

export const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
    size: 'regular',
    PopperProps: {
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8,
            },
          },
        ],
      },
    },
  },
  styleOverrides: {
    tooltip: ({ ownerState }) => ({
      backgroundColor: 'rgb(var(--colors-neutral-900))',
      borderRadius: 0,
      ...(ownerState.size === 'small'
        ? {
            padding: 'var(--s4) var(--s8)',
            fontSize: '0.75rem',
          }
        : {
            padding: 'var(--s8) var(--s16)',
            fontSize: '0.875rem',
          }),
    }),
    arrow: ({ ownerState }) => ({
      color: 'rgb(var(--colors-neutral-900))',
      ...(ownerState.size === 'regular'
        ? {
            width: '18.5px',
            height: '10.5px',
            ".MuiTooltip-popper[data-popper-placement*='bottom'] &": {
              marginTop: '-10.5px',
              marginLeft: '3px',
            },
            ".MuiTooltip-popper[data-popper-placement*='top'] &": {
              marginBottom: '-10.5px',
              marginLeft: '3px',
            },
            ".MuiTooltip-popper[data-popper-placement*='left'] &": {
              width: '10.5px',
              height: '18.5px',
              marginRight: '-10.5px',
              marginTop: '3px',
            },
            ".MuiTooltip-popper[data-popper-placement*='right'] &": {
              width: '10.5px',
              height: '18.5px',
              marginLeft: '-10.5px',
              marginBottom: '3px',
            },
          }
        : {
            width: '16.5px',
            height: '10.5px',
          }),
    }),
  },
}
