import { FundStrategy } from '@cais-group/funds-pre-trade/domain/api'

import { CloudinaryImage } from '../cloudinary-image'

import { ReactComponent as DigitalAssetsLogo } from './strategy-logos/Digital Assets.svg'
import { ReactComponent as HedgeFundsLogo } from './strategy-logos/Hedge Funds.svg'
import { ReactComponent as InfrastructureLogo } from './strategy-logos/Infrastructure.svg'
import { ReactComponent as PrivateDebtLogo } from './strategy-logos/Private Debt.svg'
import { ReactComponent as PrivateEquityLogo } from './strategy-logos/Private Equity.svg'
import { ReactComponent as PrivateRealEstateLogo } from './strategy-logos/Private Real Estate.svg'

/*

This can be generated by exporting the following environment variable (it shouldn't change unless the API_KEY is rotated):

export CLOUDINARY_URL=cloudinary://API_KEY:SECRET@cais

then running the following node.js script:

var cloudinary = require("cloudinary").v2;

console.log(
  cloudinary.url("fund-manager-logos.json", { type: "list", sign_url: true })
);

*/
const FUND_MANGER_LOGO_LIST_ENDPOINT =
  'https://res.cloudinary.com/cais/image/list/s--OYh5Ud1p--/fund-manager-logos.json'

const fundManagerLogoList: Record<string, string> = {}

fetch(FUND_MANGER_LOGO_LIST_ENDPOINT)
  .then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        data.resources.forEach(
          (resource: {
            public_id: string
            secure_url: string
            context: { custom: { fundManagerShortNames: string } }
          }) => {
            const fundManager = resource.public_id.replace(
              'fund-manager-logos/',
              ''
            )
            fundManagerLogoList[fundManager] = resource.public_id
            if (resource?.context?.custom?.fundManagerShortNames) {
              let otherNames = []
              try {
                otherNames = JSON.parse(
                  resource.context.custom.fundManagerShortNames
                )
              } catch (e) {
                console.error(
                  'Error parsing',
                  fundManager,
                  resource.context.custom.fundManagerShortNames
                )
              }
              otherNames.forEach((name: string) => {
                fundManagerLogoList[name] = resource.public_id
              })
            }
          }
        )
      })
    }
  })
  .catch(() => {
    console.error('Failed to load fund manager logos')
  })

const STRATEGY_LOGO: Partial<Record<FundStrategy, React.ReactNode>> = {
  [FundStrategy.DIGITAL_ASSETS]: <DigitalAssetsLogo />,
  [FundStrategy.EQUITY_HEDGE]: <HedgeFundsLogo />,
  [FundStrategy.INFRASTRUCTURE]: <InfrastructureLogo />,
  [FundStrategy.PRIVATE_DEBT]: <PrivateDebtLogo />,
  [FundStrategy.PRIVATE_EQUITY]: <PrivateEquityLogo />,
  [FundStrategy.REAL_ESTATE]: <PrivateRealEstateLogo />,
}

type FundManagerLogoProps = {
  fundManager: string
  strategy?: FundStrategy | null
  size?: 'small' | 'large'
  testId?: string
}

export function FundManagerLogo({
  fundManager,
  strategy,
  size = 'small',
  testId,
}: FundManagerLogoProps) {
  let logo = null
  if (fundManagerLogoList[fundManager]) {
    logo =
      size === 'small' ? (
        <CloudinaryImage
          layout="fixed"
          height={38}
          width={69}
          src={fundManagerLogoList[fundManager]}
          className="h-full w-full"
          data-testid={testId}
        />
      ) : (
        <CloudinaryImage
          layout="fullWidth"
          priority
          src={fundManagerLogoList[fundManager]}
          className="h-full w-full"
          data-testid={testId}
        />
      )
  } else if (strategy && STRATEGY_LOGO[strategy]) {
    logo = (
      <div className="h-auto w-1/2" data-testid={testId}>
        {STRATEGY_LOGO[strategy]}
      </div>
    )
  } else {
    logo = (
      <div className="h-auto w-1/2" data-testid={testId}>
        <PrivateDebtLogo />
      </div>
    )
  }
  return logo
}
