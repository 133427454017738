import { Announcement } from '../../../../equity/web/labs/announcement'
import {
  useGetAnnouncements,
  useGetBffAnnouncements,
} from '../../../domain/contentful/announcements'
import { FeatureFlag } from '../../feature-flag'

export const HOMEPAGE_BFF_FEATURE_FLAG = 'release-homepage-bff'

const BffAnnouncementContainer = () => {
  const { announcement } = useGetBffAnnouncements(
    {
      id: 'homepage',
    },
    {
      allowSingleDismissalPerSession: true,
    }
  )

  return announcement ? <Announcement {...announcement} /> : null
}

const AnnouncementContainer = () => {
  const { announcement } = useGetAnnouncements(
    {
      id: 'homepage',
    },
    {
      allowSingleDismissalPerSession: true,
    }
  )

  return announcement ? <Announcement {...announcement} /> : null
}

// The FeatureFlag wrapper prevents us from making an extra unnecessary network call
export const AnnouncementBanner = () => {
  return (
    <FeatureFlag
      featureFlagKey={HOMEPAGE_BFF_FEATURE_FLAG}
      alternative={<AnnouncementContainer />}
    >
      <BffAnnouncementContainer />
    </FeatureFlag>
  )
}
