import { Components } from '@mui/material'

import { LabelCssObject } from '../../../../../legacy-approved/ui/typography'

export const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      '.MuiFormControlLabel-label': {
        ...LabelCssObject,
        color: 'rgb(var(--colors-neutral-600))',
      },
    },
  },
}
