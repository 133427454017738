import { NavigateFunction, useNavigate } from 'react-router-dom'

import {
  FundProductV1,
  FundStrategy,
} from '@cais-group/funds-pre-trade/domain/api'
import { StructuredProductV1 } from '@cais-group/shared/domain/product-master-api'

import { Icon } from '../../../../../../../equity/web/atoms/icon'
import { FundManagerLogo } from '../../../../../../ui/fund-manager-logo'
import { linkToCaisIq } from '../../../../../../ui/main-nav'
import { SearchResult } from '../search-result'

const VIEW_PRODUCT_ROUTE = (id: string) => `/funds-pre-trade/funds/${id}`
const INVEST_IN_PRODUCT_ROUTE = (id: string) =>
  `/funds-trade/orders/create?fundId=${id}`
const CAISIQ_LEARN_ROUTE = (id: string) => linkToCaisIq('?fundId=' + id)

type Props = {
  products: FundProductV1[]
  spProducts: StructuredProductV1[]
  searchText?: string
}

export const Products = ({ products, spProducts, searchText }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-y-8">
      <div className="small-strong">
        {products.length + spProducts.length} products
      </div>
      {products.slice(0, 3).map((product) => {
        return (
          <SearchResult
            key={product.id}
            label={product.generalInformation.legalName}
            actions={getAvailableProductActions(product, navigate)}
            type="Fund"
            highlight={searchText}
            renderAvatar={() => (
              <FundManagerLogo
                fundManager={
                  product?.generalInformation.fundManager?.shortName || ''
                }
                strategy={
                  (product.generalInformation.strategy as FundStrategy) || null
                }
                size="small"
              />
            )}
          />
        )
      })}
      {spProducts
        .slice(0, 3 + (products?.length < 4 ? 3 - products.length : 0))
        .map((product) => {
          return (
            <SearchResult
              key={product.id}
              label={
                product?.structure?.name?.value ||
                product.basketName?.value ||
                ''
              }
              actions={[
                {
                  text: 'View Product',
                  handler: () => navigate(VIEW_PRODUCT_ROUTE(product.id)),
                },
                {
                  text: 'Create Order',
                  handler: () => navigate(INVEST_IN_PRODUCT_ROUTE(product.id)),
                },
              ]}
              highlight={searchText}
              type="Structured Product"
              renderAvatar={() => (
                <Icon type="ProductOutlined" color="eq-color-neutral-600" />
              )}
            />
          )
        })}
    </div>
  )
}

function getAvailableProductActions(
  product: FundProductV1,
  navigate: NavigateFunction
) {
  return [
    ...(product.allowedActions?.includes('VIEW')
      ? [
          {
            text: 'View Product',
            handler: () => navigate(VIEW_PRODUCT_ROUTE(product.id)),
          },
        ]
      : []),
    ...(product.allowedActions?.includes('INVEST')
      ? [
          {
            text: 'Create Order',
            handler: () => navigate(INVEST_IN_PRODUCT_ROUTE(product.id)),
          },
        ]
      : []),
    ...(product.deniedActions?.some(({ action }) => action === 'INVEST') &&
    product.allowedActions?.includes('LEARN')
      ? [
          {
            text: 'Learn to Invest',
            handler: () => navigate(CAISIQ_LEARN_ROUTE(product.id)),
          },
        ]
      : []),
  ]
}
