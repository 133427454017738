/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Portfolios API
 * API for portfolios management
 * OpenAPI spec version: v1
 */
export type DeleteLimitsByIdHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdateLimitsByIdHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type DeleteIndexChoicesByIdHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdateIndexChoicesByIdHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type DeleteCmasByIdHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdateCmasByIdHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UploadPortfolioDocumentBody = {
  file: Blob
  request: PortfolioDocumentUploadRequest
}

export type PatchPortfolioHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type DeletePortfolioHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdatePortfolioHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

/**
 * Internal Server Error
 */
export type N500Response = ErrorResponseV1

/**
 * Precondition failed
 */
export type N412Response = ErrorResponseV1

/**
 * Conflict
 */
export type N409Response = ErrorResponseV1

/**
 * Not found
 */
export type N404Response = ErrorResponseV1

/**
 * User not authorized to perform operation
 */
export type N403Response = ErrorResponseV1

/**
 * User not authenticated
 */
export type N401Response = ErrorResponseV1

/**
 * Bad request
 */
export type N400Response = ErrorResponseV1

/**
 * The operation to be performed
 */
export type PatchDocumentOp =
  (typeof PatchDocumentOp)[keyof typeof PatchDocumentOp]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchDocumentOp = {
  add: 'add',
  remove: 'remove',
  replace: 'replace',
} as const

/**
 * A JSONPatch document as defined by RFC 6902 https://jsonpatch.com/
 */
export interface PatchDocument {
  /** Previous value for replace operation */
  fromValue?: string
  /** The operation to be performed */
  op: PatchDocumentOp
  /** A JSON-Pointer */
  path: string
  /** The value to be used within the operations. */
  value?: unknown
}

/**
 * The type of action which the event occurred (create|update|delete|read)
 */
export type EnvelopeTypeActionType =
  (typeof EnvelopeTypeActionType)[keyof typeof EnvelopeTypeActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvelopeTypeActionType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  read: 'read',
} as const

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export interface EnvelopeTypeAction {
  /** The name of action which the event occurred */
  name: string
  /** The type of action which the event occurred (create|update|delete|read) */
  type: EnvelopeTypeActionType
}

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export type EnvelopeType = {
  action: EnvelopeTypeAction
  /** The list of actions which describe the changes in the event occurred */
  actions?: EnvelopeTypeAction[]
  /** The domain of the event */
  domain: string
  /** The type of resource upon which the event occurred */
  resource: string
  /** The schema major version of the event type */
  version: number
}

export type EnvelopeRelatedIdsItem = {
  /** The domain of the related resource ID */
  domain: string
  /** Unique ID of the related resource ID */
  id: string
  /** The resource type of the related ID */
  resource: string
  /** The schema major version of the resource of the related ID */
  version?: number
}

/**
 * Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user.
 */
export type EnvelopeActor = {
  /** The ID of the real user. */
  actorId: string
  /** The ID of the effective user. Only required where the real user is different from the effective user. */
  switchActorId?: string | null
}

/**
 * CAIS Event envelope model
 */
export interface Envelope {
  /** Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user. */
  actor?: EnvelopeActor
  /**
   * Deprecated. Use actor instead.
   * @deprecated
   */
  actorId?: string
  /** Optional correlation identifier which can be used to logically group or associate events */
  correlationId?: string
  /** Human readable description of the event */
  description: string
  /** The list of differences represented as JSONPatch PatchDocument objects */
  diff?: PatchDocument[]
  /** The ID of the firm that owns the resource. May not be present if the event does not relate to a specific resource. */
  firmId?: string
  /** Unique identifier for an event */
  id: string
  /** An optional list of related IDs. As an example an Order event may include a related product's ID */
  relatedIds?: EnvelopeRelatedIdsItem[]
  /** The ID of the resource affected by the event. May not be present if the event does not relate to a specific resource. */
  resourceId?: string
  /** ISO Datetime when the event occurred. */
  timestamp: string
  /** Object to specify the type of the event enclosed within the envelope. */
  type: EnvelopeType
}

/**
 * Use this filter to emulate enumerated filters in OpenAPI schemas. \ Since JSON/OpenAPI does not support generic types, it is not possible to define generic EnumFilter schema. Specific instances of EnumFilter must be instantiated from this filter within each application.
 */
export type StrictStringFilter = {
  eq?: string | null
  exists?: boolean | null
  in?: string[] | null
  ne?: string | null
  notIn?: string[] | null
} | null

export type UUIDFilter = {
  eq?: string | null
  in?: string[] | null
  notIn?: string[] | null
} | null

export type BigDecimalFilter = {
  eq?: number | null
  exists?: boolean | null
  gt?: number | null
  gte?: number | null
  in?: number[] | null
  lt?: number | null
  lte?: number | null
  ne?: number | null
  notIn?: number[] | null
} | null

export type StringFilter = {
  caseSensitive?: boolean | null
  eq?: string | null
  exists?: boolean | null
  in?: string[] | null
  like?: string | null
  ne?: string | null
  notIn?: string[] | null
} | null

export type PageInfo = {
  endCursor?: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor?: string | null
} | null

export type PageRequest = {
  after?: string | null
  before?: string | null
  first?: number | null
  last?: number | null
} | null

export interface MoneyV1 {
  /** Monetary amount */
  amount: number
  /** ISO 4217 currency code */
  currency: string
}

export type SummaryFeedbackEventV1 = Envelope & {
  payload: SummaryFeedbackV1
}

export interface EntitlementsEventPayloadV1 {
  entitlements: EntitlementsMapV1
  /** The firm id the entitlements belong to */
  firmId: string
}

export type EntitlementsEventV1 = Envelope & {
  payload: EntitlementsEventPayloadV1
}

export type LimitsEventV1 = Envelope & {
  payload: LimitsV1
}

export type IndexChoicesEventV1 = Envelope & {
  payload: IndexChoicesV1
}

export type CmasEventV1 = Envelope & {
  payload: CmasV1
}

export interface OrderDraftFundV1 {
  currencyAmount: MoneyV1
  /** ID of the fund */
  fundId: string
}

export interface OrderDraftV1 {
  /** User who created the order draft */
  createdBy: string
  /** Date and time when the order draft was created */
  createdDate: string
  /** Funds in the order draft */
  funds: OrderDraftFundV1[]
  /** ID of the order draft */
  id: string
  /** ID of the portfolio */
  portfolioId: string
  /** Name of the portfolio */
  portfolioName: string
  /** Version of the portfolio */
  portfolioVersion: number
  /** Date and time when the order draft was last updated */
  updatedDate: string
  /** Version of the order draft */
  version: number
}

export interface OrderDraftRequestV1 {
  /** Funds in the order draft */
  funds: OrderDraftFundV1[]
  /** ID of the portfolio */
  portfolioId: string
  /** Version of the portfolio */
  portfolioVersion: number
}

export interface FundStrategyV1 {
  /** Display name of the fund strategy */
  displayName: string
  /** Name of the fund strategy */
  name: string
}

export interface AssetClassFundStrategyMappingsV1 {
  /** Asset class */
  assetClass: string
  fundStrategies: FundStrategyV1[]
}

export interface PagedSummaryFeedbackListItemsV1 {
  items?: SummaryFeedbackV1[]
  pageInfo?: PageInfo
}

export interface SummaryFeedbackFilterV1 {
  createdDate?: PortfoliosOffsetDateTimeFilterV1
  sentiment?: StringFilter
  userId?: UUIDFilter
}

export type SummaryFeedbackSearchRequestV1SortingItem =
  (typeof SummaryFeedbackSearchRequestV1SortingItem)[keyof typeof SummaryFeedbackSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SummaryFeedbackSearchRequestV1SortingItem = {
  CREATED_DATE_ASC: 'CREATED_DATE_ASC',
  CREATED_DATE_DESC: 'CREATED_DATE_DESC',
  SENTIMENT_ASC: 'SENTIMENT_ASC',
  SENTIMENT_DESC: 'SENTIMENT_DESC',
} as const

export interface SummaryFeedbackSearchRequestV1 {
  filter?: SummaryFeedbackFilterV1
  pageRequest?: PageRequest
  sorting?: SummaryFeedbackSearchRequestV1SortingItem[]
}

export interface SummaryFeedbackV1 {
  /** Additional information for the feedback */
  content?: string
  /** Date and time when the feedback was created */
  createdDate: string
  /** ID of the feedback */
  id: string
  /** The sentiment - POSITIVE or NEGATIVE */
  sentiment: string
  /** ID of the user who provided the feedback */
  userId: string
}

export interface SummaryFeedbackRequestV1 {
  /** Additional information for the feedback */
  content?: string
  /** The sentiment - POSITIVE or NEGATIVE */
  sentiment: string
}

export interface CtaStatusV1 {
  /** Whether the user has accepted the CTA */
  acceptance: boolean
  /** Date and time when the user accepted the CTA */
  acceptanceDate?: string
  /** The ID of the user */
  userId: string
}

export interface ReportPayloadV1 {
  /** ID of the portfolio to compare */
  comparePortfolioId?: string
  /** Version of the portfolio to compare */
  comparePortfolioVersion?: number
  /** The ID of the saved report file */
  fileId: string
  /** The name of the saved report file */
  fileName: string
  /** The version of the saved report file */
  fileVersion: number
  /** ID of the report event */
  id: string
  /** ID of the portfolio */
  portfolioId: string
  /** Version of the portfolio */
  portfolioVersion: number
}

export type ReportEventV1 = Envelope & {
  payload: ReportPayloadV1
}

export type PortfolioEventV1 = Envelope & {
  payload: PortfolioV1
}

/**
 * Additional error attributes
 */
export type ErrorResponseV1ErrorAttributes = { [key: string]: any }

export interface ErrorResponseV1 {
  /** Display message */
  displayMessage?: string
  /** Additional error attributes */
  errorAttributes?: ErrorResponseV1ErrorAttributes
  /** Error code */
  errorCode?: string
  /** Error message */
  errorMessage?: string
  /** URI to the error code documentation */
  errorUri?: string
  /** HTTP status code */
  status?: number
}

export interface ModelPortfolioAuthorisedFirmV1 {
  id: string
  name: string
}

export interface ModelPortfolioAuthorisedUserV1 {
  email: string
  id: string
  name: string
}

export interface ModelPortfolioAuthorisationsV1 {
  firms?: ModelPortfolioAuthorisedFirmV1[]
  users?: ModelPortfolioAuthorisedUserV1[]
}

export interface ModelPortfolioAuthorisationRequestV1 {
  firms: string[]
  users: string[]
}

export interface PortfolioSharedUserV1 {
  email: string
  id: string
  name: string
}

export interface PortfolioSharedTeamV1 {
  id: string
  name: string
  users: PortfolioSharedUserV1[]
}

export interface PortfolioShareInformationV1 {
  teams?: PortfolioSharedTeamV1[]
  users?: PortfolioSharedUserV1[]
}

export interface PermissionsV1 {
  /** The action the permissions apply to */
  action: string
  /** The resource the permissions apply to */
  resourceType: string
  /** The scope of the permissions */
  scope?: string
}

export interface AnalysisDateRangeV1 {
  /** End date */
  endDate: string
  /** Start date */
  startDate: string
}

export interface AnalyticsDateRangeResponseV1 {
  /** End date */
  endDate: string
  /** Request Label */
  label: string
  /** Start date */
  startDate: string
}

export interface AnalyticsDateRangeLastPeriodsV1 {
  /** Date to go back from */
  date?: string
  /** Number of periods to go back from the last available date or the provided date */
  periods: number
  /** Period type, years/months */
  type: string
}

export interface AnalyticsDateRangeRequestV1 {
  /** End date */
  endDate?: string
  /** If true, the full history of the data will be returned */
  fullHistory?: boolean
  /** Request Label */
  label: string
  lastPeriods?: AnalyticsDateRangeLastPeriodsV1
  /** Start date */
  startDate?: string
}

export interface AllocationSummaryV1 {
  /** Date and time when the allocation summary was created */
  createdDate: string
  /** ID of the allocation summary */
  id: string
  /** Summary of the allocation */
  summary: string
}

export interface AllocationSummaryRequestV1 {
  /** Portfolio summary statistics request items */
  items: PortfolioSummaryStatsRequestItemV1[]
}

export interface InvestmentRegistrationRequestV1 {
  /** Whether or not the user has invested in the portfolio */
  invested?: boolean
  /** The ID of the user who's invested in the portfolio */
  userId: string
}

export interface PortfolioDocumentDownloadLinkV1 {
  downloadUrl: string
  expiresAt: string
}

export interface PortfolioDocumentUploadRequest {
  /** The document's category */
  category: string
  /** The document's content type */
  contentType: string
  /** The original fileName for the document */
  fileName: string
  /** The document's title */
  title: string
  yearMonth?: string
}

export interface PortfolioDocumentV1 {
  /** The document's category */
  category: string
  /** The document's content type */
  contentType: string
  /** User who created the document */
  createdBy: string
  /** Date and time when the document was created */
  createdDate: string
  /** User who deleted the document */
  deletedBy?: string
  /** Date and time when the document was deleted */
  deletedDate?: string
  /** The file's ID in File Storage */
  fileId: string
  /** The original fileName for the document */
  fileName: string
  /** The document's ID */
  id: string
  /** The ID of the portfolio this document belongs to */
  portfolioId: string
  /** The document's title */
  title: string
  /** User who last updated the document */
  updatedBy?: string
  /** Date and time when the document was last updated */
  updatedDate?: string
  yearMonth?: string
}

export interface PagedPortfolioDocumentV1 {
  items?: PortfolioDocumentV1[]
  pageInfo?: PageInfo
}

export interface PortfolioDocumentFilterV1 {
  category?: StrictStringFilter
  title?: StringFilter
}

export type PortfolioDocumentSearchRequestV1SortingItem =
  (typeof PortfolioDocumentSearchRequestV1SortingItem)[keyof typeof PortfolioDocumentSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortfolioDocumentSearchRequestV1SortingItem = {
  CREATED_DATE_ASC: 'CREATED_DATE_ASC',
  CREATED_DATE_DESC: 'CREATED_DATE_DESC',
  TITLE_ASC: 'TITLE_ASC',
  TITLE_DESC: 'TITLE_DESC',
  CATEGORY_ASC: 'CATEGORY_ASC',
  CATEGORY_DESC: 'CATEGORY_DESC',
  YEAR_MONTH_ASC: 'YEAR_MONTH_ASC',
  YEAR_MONTH_DESC: 'YEAR_MONTH_DESC',
} as const

export interface PortfolioDocumentSearchRequestV1 {
  filter?: PortfolioDocumentFilterV1
  pageRequest?: PageRequest
  sorting?: PortfolioDocumentSearchRequestV1SortingItem[]
}

export interface PortfolioObjectiveScoringV1 {
  /** Diversify risk scoring */
  DIVERSIFY_RISK?: number
  /** Enhance returns scoring */
  ENHANCE_RETURNS?: number
  /** Preserve capital scoring */
  PRESERVE_CAPITAL?: number
  /** Supplement income scoring */
  SUPPLEMENT_INCOME?: number
}

/**
 * Valid JSON patch request - https://jsonpatch.com
 */
export type PatchRequest = unknown

export interface IndexV1 {
  /** Asset class */
  assetClass: string
  /** ID of the index */
  id: string
  /** Name of the index */
  name: string
}

export interface CorrelationMatrixValueV1 {
  [key: string]: number
}

export type CorrelationMatrixV1Matrix = {
  [key: string]: CorrelationMatrixValueV1
}

export interface CorrelationMatrixV1 {
  analysisDateRange: AnalysisDateRangeV1
  /** Sampling Frequency of the underlying data */
  frequency: string
  matrix: CorrelationMatrixV1Matrix
}

/**
 * Type of the limits (soft limits can be ignored)
 */
export type LimitsRequestV1Type =
  (typeof LimitsRequestV1Type)[keyof typeof LimitsRequestV1Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LimitsRequestV1Type = {
  SOFT: 'SOFT',
  HARD: 'HARD',
} as const

export interface LimitsRequestV1 {
  limits: LimitValuesV1[]
  /** Name of the limits */
  name?: string
  /** Type of the limits (soft limits can be ignored) */
  type: LimitsRequestV1Type
}

/**
 * Risk profile these limits apply to
 */
export type LimitValuesV1RiskProfile =
  (typeof LimitValuesV1RiskProfile)[keyof typeof LimitValuesV1RiskProfile]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LimitValuesV1RiskProfile = {
  CONSERVATIVE: 'CONSERVATIVE',
  BALANCED: 'BALANCED',
  AGGRESSIVE: 'AGGRESSIVE',
  DEFAULT: 'DEFAULT',
} as const

/**
 * Max allocation per asset class (missing means no limit)
 */
export type LimitValuesV1AssetClasses = { [key: string]: number }

export interface LimitValuesV1 {
  /** Max allocation per asset class (missing means no limit) */
  assetClasses?: LimitValuesV1AssetClasses
  /** Min liquidity required */
  liquidity?: number
  /** Risk profile these limits apply to */
  riskProfile: LimitValuesV1RiskProfile
}

/**
 * Type of the limits (soft limits can be ignored)
 */
export type LimitsV1Type = (typeof LimitsV1Type)[keyof typeof LimitsV1Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LimitsV1Type = {
  SOFT: 'SOFT',
  HARD: 'HARD',
} as const

export interface LimitsV1 {
  /** ID of the firm that owns these limits */
  firmId?: string
  /** ID of the limits */
  id: string
  limits: LimitValuesV1[]
  /** Name of the limits */
  name?: string
  /** Type of the limits (soft limits can be ignored) */
  type: LimitsV1Type
  /** Version of the limits */
  version: number
}

export interface CmasMatrixValueV1 {
  [key: string]: number
}

export type CmasV1Risks = { [key: string]: number }

export type CmasV1Returns = { [key: string]: number }

export type CmasV1Correlations = { [key: string]: CmasMatrixValueV1 }

export interface CmasV1 {
  correlations: CmasV1Correlations
  /** User who created the CMAs */
  createdBy?: string
  /** Date and time when the CMAs were created */
  createdDate: string
  /** CMAs disclosure */
  disclosure?: string
  /** firm id of the firm that owns the CMAs */
  firmId?: string
  /** ID of the CMAs */
  id: string
  /** Is this the default CMAs */
  isDefault: boolean
  /** Name of the CMAs */
  name?: string
  returns: CmasV1Returns
  risks: CmasV1Risks
  /** Date and time when the CMAs were last updated */
  updatedDate: string
  /** user id of the user that owns the CMAs */
  userId?: string
  /** Version of the CMAs */
  version: number
}

export type CmasRequestV1Risks = { [key: string]: number }

export type CmasRequestV1Returns = { [key: string]: number }

export type CmasRequestV1Correlations = { [key: string]: CmasMatrixValueV1 }

export interface CmasRequestV1 {
  correlations: CmasRequestV1Correlations
  /** Is this the default CMAs */
  isDefault?: boolean
  /** Name of the CMAs */
  name?: string
  returns: CmasRequestV1Returns
  risks: CmasRequestV1Risks
}

export type IndexChoicesRequestV1IndexChoices = { [key: string]: string }

export interface IndexChoicesRequestV1 {
  indexChoices: IndexChoicesRequestV1IndexChoices
  /** Is this the default index choices */
  isDefault?: boolean
  /** Name of the index choices */
  name?: string
}

export type IndexChoicesV1IndexChoices = { [key: string]: string }

export interface IndexChoicesV1 {
  /** User who created the index choices */
  createdBy?: string
  /** Date and time when the index choices were created */
  createdDate: string
  /** firm id of the firm that owns the index choices */
  firmId?: string
  /** ID of the index choices */
  id: string
  indexChoices: IndexChoicesV1IndexChoices
  /** Is this the default index choices */
  isDefault: boolean
  /** Name of the index choices */
  name?: string
  /** Date and time when the index choices were last updated */
  updatedDate: string
  /** user id of the user that owns the index choices */
  userId?: string
  /** Version of the index choices */
  version: number
}

export interface SettingsIdsV1 {
  capitalMarketAssumptions: string
  indexChoices: string
  limits?: string
}

export interface AvailableSettingsV1 {
  active: SettingsIdsV1
  /** Actions the current user is allowed to perform on settings */
  allowedActions: string[]
  capitalMarketAssumptions: CmasV1[]
  /** Actions the current user is *not* allowed to perform on settings */
  deniedActions: PortfolioDeniedActionV1[]
  indexChoices: IndexChoicesV1[]
  limits?: LimitsV1[]
}

export interface SettingsV1 {
  capitalMarketAssumptions: CmasV1
  indexChoices: IndexChoicesV1
  limits?: LimitsV1
}

export interface ActiveSettingsRequestV1 {
  capitalMarketAssumptions?: string
  indexChoices?: string
}

/**
 * Map of entitlements type -> enabled flag
 */
export interface EntitlementsMapV1 {
  [key: string]: boolean
}

export interface IPSEntityV1 {
  /** ID of the IPS entity */
  id: string
  /** Name of the IPS entity */
  name: string
}

export interface PortfolioReportRequestV1 {
  /** ID of the index to compare */
  comparedIndexId?: string
  /** ID of the portfolio to compare */
  comparedPortfolioId?: string
  /** ID of the portfolio */
  portfolioId: string
  /** ID of the summary */
  summaryId?: string
}

export interface AssetClassLimitV1 {
  /** Asset class */
  assetClass: string
  /** Maximum allocation percentage (0 - 1) */
  limit: number
}

/**
 * Percent of the portfolio allocated to stocks (0 - 1)
 */
export type ReferenceAllocationsRequestV1RiskProfile =
  (typeof ReferenceAllocationsRequestV1RiskProfile)[keyof typeof ReferenceAllocationsRequestV1RiskProfile]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReferenceAllocationsRequestV1RiskProfile = {
  CONSERVATIVE_20_80: 'CONSERVATIVE_20_80',
  CONSERVATIVE_30_70: 'CONSERVATIVE_30_70',
  CONSERVATIVE_40_60: 'CONSERVATIVE_40_60',
  BALANCED_50_50: 'BALANCED_50_50',
  BALANCED_60_40: 'BALANCED_60_40',
  BALANCED_70_30: 'BALANCED_70_30',
  AGGRESSIVE_80_20: 'AGGRESSIVE_80_20',
  AGGRESSIVE_90_10: 'AGGRESSIVE_90_10',
  AGGRESSIVE_100_0: 'AGGRESSIVE_100_0',
} as const

export interface ReferenceAllocationsRequestV1 {
  /** Asset class limits */
  assetClassLimits?: AssetClassLimitV1[]
  /** Percent of the portfolio that should be liquid (0 - 1) */
  minLiquidity: number
  /** Percent of the portfolio allocated to stocks (0 - 1) */
  riskProfile: ReferenceAllocationsRequestV1RiskProfile
}

export type PortfoliosOffsetDateTimeRangeFilterV1 = {
  gt?: string
  gte?: string
  lt?: string
  lte?: string
} | null

export type PortfoliosOffsetDateTimeFilterV1 = {
  eq?: string | null
  exists?: boolean | null
  gt?: string | null
  gte?: string | null
  inWithRange?: PortfoliosOffsetDateTimeRangeFilterV1[]
  lt?: string | null
  lte?: string | null
  ne?: string | null
} | null

export type StrictArrayFilterV1 = {
  /** Matches if every value in the target array is in the filter array */
  in?: string[] | null
} | null

export type ArrayFilterV1 = {
  in?: string[] | null
  overlap?: string[] | null
} | null

export interface PortfolioFilterV1 {
  createdBy?: UUIDFilter
  createdDate?: PortfoliosOffsetDateTimeFilterV1
  linkedAccount?: UUIDFilter
  linkedContact?: UUIDFilter
  linkedHousehold?: UUIDFilter
  minInitialInvestment?: BigDecimalFilter
  minInvestorLevel?: StringFilter
  minLiquidity?: BigDecimalFilter
  name?: StringFilter
  objective?: ArrayFilterV1
  riskProfile?: StringFilter
  subscriptionStatus?: StringFilter
  taxReporting?: StrictArrayFilterV1
  type?: StringFilter
  updatedDate?: PortfoliosOffsetDateTimeFilterV1
}

export interface PortfolioListItemV1 {
  /** Portfolio allocations */
  allocations?: PortfolioAllocationV1[]
  /** Actions the current user is allowed to perform on the portfolio */
  allowedActions?: string[]
  /** Asset class limits */
  assetClassLimits?: AssetClassLimitV1[]
  /** User who created the portfolio */
  createdBy: string
  /** Date and time when the portfolio was created */
  createdDate: string
  /** Actions the current user is *not* allowed to perform on the portfolio */
  deniedActions?: PortfolioDeniedActionV1[]
  /** Description of the portfolio */
  description?: string
  /** Portfolio disclaimer */
  disclaimer?: string
  /** The id of the firm that owns the portfolio */
  firmId?: string
  /** The name of the firm that owns the portfolio */
  firmName?: string
  /** Portfolio fund allocations */
  fundAllocations?: PortfolioFundAllocationV1[]
  /** ID of the portfolio */
  id: string
  /** Linked accounts */
  linkedAccounts?: IPSEntityV1[]
  /** Linked households */
  linkedContacts?: IPSEntityV1[]
  /** Linked households */
  linkedHouseholds?: IPSEntityV1[]
  /** Lockup terms of the portfolio */
  lockupTerms?: string
  /** Minimum additional investment */
  minAdditionalInvestment?: number
  /** Minimum initial investment */
  minInitialInvestment?: number
  /** Minimum investor level */
  minInvestorLevel?: string
  /** Minimum liquidity */
  minLiquidity?: number
  /** Name of Portfolio */
  name: string
  objectiveScoring?: PortfolioObjectiveScoringV1
  /** The name of the user who owns the portfolio */
  ownerName?: string
  placeholderFundAllocations?: PortfolioPlaceholderFundAllocationV1[]
  /** Primary objective of the portfolio */
  primaryObjective?: string
  /** Redemption terms of the portfolio */
  redemptionTerms?: string
  /** Risk profile */
  riskProfile?: string
  /** Secondary objective of the portfolio */
  secondaryObjective?: string
  /** Series of the portfolio */
  series?: string
  sharedTeams?: string[]
  sharedUsers?: string[]
  /** Short description of the portfolio */
  shortDescription?: string
  /** Status of the portfolio */
  status?: string
  /** Subscription status of the portfolio */
  subscriptionStatus?: string
  /** Tax reporting (derived from the associated funds) */
  taxReporting?: string[]
  /** The type of the portfolio - MODEL or CUSTOM_SCRATCH or CUSTOM_REFERENCE_FRAMEWORK_BASED */
  type?: string
  /** Date and time when the portfolio was updated */
  updatedDate?: string
  /** Version of the portfolio */
  version: number
}

export interface PortfolioFacetValueV1 {
  /** The count of the facet */
  count: number
  /** The ID of the facet if an id exists */
  id?: string
  /** The name of the facet if a name exists */
  name?: string
}

export type PortfolioFacetsV1Item = {
  /** The name of the facet */
  name: string
  values: PortfolioFacetValueV1[]
}

export type PortfolioFacetsV1 = PortfolioFacetsV1Item[]

export type PortfolioFacetRequestV1FacetsItem =
  (typeof PortfolioFacetRequestV1FacetsItem)[keyof typeof PortfolioFacetRequestV1FacetsItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortfolioFacetRequestV1FacetsItem = {
  LINKED_HOUSEHOLD: 'LINKED_HOUSEHOLD',
  LINKED_ACCOUNT: 'LINKED_ACCOUNT',
  LINKED_CONTACT: 'LINKED_CONTACT',
} as const

export interface PortfolioFacetRequestV1 {
  facets: PortfolioFacetRequestV1FacetsItem[]
}

export interface PortfolioCountV1 {
  /** The number of portfolios that match the search criteria */
  count: number
}

export interface PagedPortfolioListItemsV1 {
  items?: PortfolioListItemV1[]
  pageInfo?: PageInfo
}

export type PortfoliosSearchRequestV1SortingItem =
  (typeof PortfoliosSearchRequestV1SortingItem)[keyof typeof PortfoliosSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortfoliosSearchRequestV1SortingItem = {
  CREATED_DATE_ASC: 'CREATED_DATE_ASC',
  CREATED_DATE_DESC: 'CREATED_DATE_DESC',
  UPDATED_DATE_ASC: 'UPDATED_DATE_ASC',
  UPDATED_DATE_DESC: 'UPDATED_DATE_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
  RISK_PROFILE_ASC: 'RISK_PROFILE_ASC',
  RISK_PROFILE_DESC: 'RISK_PROFILE_DESC',
  MIN_LIQUIDITY_ASC: 'MIN_LIQUIDITY_ASC',
  MIN_LIQUIDITY_DESC: 'MIN_LIQUIDITY_DESC',
  TYPE_ASC: 'TYPE_ASC',
  TYPE_DESC: 'TYPE_DESC',
  CREATED_BY_ASC: 'CREATED_BY_ASC',
  CREATED_BY_DESC: 'CREATED_BY_DESC',
} as const

export interface PortfoliosSearchRequestV1 {
  filter?: PortfolioFilterV1
  pageRequest?: PageRequest
  sorting?: PortfoliosSearchRequestV1SortingItem[]
}

export interface PortfolioFundV1 {
  /** The asset class of this fund */
  assetClass: string
  /** The ID of the fund */
  id: string
  /** Whether the fund is investable */
  investable?: boolean
  /** The legal name of the fund */
  name: string
  /** The strategy of this fund */
  strategy: string
  /** The display name of the strategy of this fund */
  strategyDisplay: string
}

export interface PortfolioFundAllocationDetailsV1 {
  /** The portfolio allocation percentage as a decimal */
  allocation: number
  fund: PortfolioFundV1
}

export interface PortfolioPlaceholderFundAllocationV1 {
  /** The portfolio allocation percentage as a decimal */
  allocation: number
  /** The asset class of this fund */
  assetClass: string
  /** The description of the fund */
  description?: string
  /** The legal name of the fund */
  name: string
  /** The strategy of this fund */
  strategy?: string
}

export interface PortfolioFundAllocationV1 {
  /** The portfolio allocation percentage as a decimal */
  allocation: number
  /** The ID of the allocated fund */
  fundId: string
  /** Whether the fund is investable */
  investable?: boolean
}

/**
 * The allocation metadata
 */
export type PortfolioAllocationV1Metadata = { [key: string]: any }

export interface PortfolioAllocationV1 {
  /** The portfolio allocation percentage as a decimal */
  allocation: number
  /** The children of the allocation */
  allocations?: PortfolioAllocationV1[]
  /** The portfolio allocation asset class */
  assetClass: string
  /** The allocation metadata */
  metadata?: PortfolioAllocationV1Metadata
  /** The name of the allocation */
  name?: string
  /** The type of the allocation */
  type?: string
}

/**
 * The type of the portfolio - MODEL or CUSTOM_SCRATCH or CUSTOM_REFERENCE_FRAMEWORK_BASED
 */
export type PortfolioUpdateRequestV1Type =
  (typeof PortfolioUpdateRequestV1Type)[keyof typeof PortfolioUpdateRequestV1Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortfolioUpdateRequestV1Type = {
  MARKETPLACE_MODEL: 'MARKETPLACE_MODEL',
  MODEL: 'MODEL',
  CUSTOM_SCRATCH: 'CUSTOM_SCRATCH',
  CUSTOM_REFERENCE_FRAMEWORK_BASED: 'CUSTOM_REFERENCE_FRAMEWORK_BASED',
} as const

/**
 * Risk profile
 */
export type PortfolioUpdateRequestV1RiskProfile =
  (typeof PortfolioUpdateRequestV1RiskProfile)[keyof typeof PortfolioUpdateRequestV1RiskProfile]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortfolioUpdateRequestV1RiskProfile = {
  CONSERVATIVE_20_80: 'CONSERVATIVE_20_80',
  CONSERVATIVE_30_70: 'CONSERVATIVE_30_70',
  CONSERVATIVE_40_60: 'CONSERVATIVE_40_60',
  BALANCED_50_50: 'BALANCED_50_50',
  BALANCED_60_40: 'BALANCED_60_40',
  BALANCED_70_30: 'BALANCED_70_30',
  AGGRESSIVE_80_20: 'AGGRESSIVE_80_20',
  AGGRESSIVE_90_10: 'AGGRESSIVE_90_10',
  AGGRESSIVE_100_0: 'AGGRESSIVE_100_0',
} as const

export interface PortfolioUpdateRequestV1 {
  /** Portfolio allocations */
  allocations: PortfolioAllocationV1[]
  /** Asset class limits */
  assetClassLimits?: AssetClassLimitV1[]
  /** Description of the portfolio */
  description?: string
  /** Portfolio disclaimer */
  disclaimer?: string
  /** The id of the firm that owns the portfolio */
  firmId?: string
  /** Portfolio fund allocations */
  fundAllocations?: PortfolioFundAllocationV1[]
  /** Linked accounts */
  linkedAccounts?: string[]
  /** Linked contacts */
  linkedContacts?: string[]
  /** Linked households */
  linkedHouseholds?: string[]
  /** Lockup terms of the portfolio */
  lockupTerms?: string
  /** Minimum additional investment */
  minAdditionalInvestment?: number
  /** Minimum initial investment */
  minInitialInvestment?: number
  /** Minimum investor level */
  minInvestorLevel?: string
  /** Minimum liquidity */
  minLiquidity?: number
  /** Name of Portfolio */
  name: string
  objectiveScoring?: PortfolioObjectiveScoringV1
  /** Portfolio placeholder fund allocations */
  placeholderFundAllocations?: PortfolioPlaceholderFundAllocationV1[]
  /** Primary objective of the portfolio */
  primaryObjective?: string
  /** Redemption terms of the portfolio */
  redemptionTerms?: string
  /** Risk profile */
  riskProfile?: PortfolioUpdateRequestV1RiskProfile
  /** Secondary objective of the portfolio */
  secondaryObjective?: string
  /** Series of the portfolio */
  series?: string
  sharedTeams?: string[]
  sharedUsers?: string[]
  /** Short description of the portfolio */
  shortDescription?: string
  /** Status of the portfolio */
  status?: string
  /** Subscription status of the portfolio */
  subscriptionStatus?: string
  /** Tax reporting for the Portfolio (derived from the associated funds if empty) */
  taxReporting?: string[]
  /** The type of the portfolio - MODEL or CUSTOM_SCRATCH or CUSTOM_REFERENCE_FRAMEWORK_BASED */
  type?: PortfolioUpdateRequestV1Type
}

export interface PortfolioDeniedActionV1 {
  /** The name of the action that was denied */
  action: string
  /** The reasons explaining why the action was denied */
  reasons?: string[]
}

export interface PortfolioV1 {
  /** Portfolio allocations */
  allocations?: PortfolioAllocationV1[]
  /** Actions the current user is allowed to perform on the portfolio */
  allowedActions: string[]
  /** Asset class limits */
  assetClassLimits?: AssetClassLimitV1[]
  /** User who created the portfolio */
  createdBy?: string
  /** Date and time when the portfolio was created */
  createdDate?: string
  /** Actions the current user is *not* allowed to perform on the portfolio */
  deniedActions: PortfolioDeniedActionV1[]
  /** Description of the portfolio */
  description?: string
  /** Portfolio disclaimer */
  disclaimer?: string
  /** The id of the firm that owns the portfolio */
  firmId?: string
  /** The name of the firm that owns the portfolio */
  firmName?: string
  /** Portfolio fund allocations */
  fundAllocations?: PortfolioFundAllocationDetailsV1[]
  /** ID of the portfolio */
  id: string
  /** Linked accounts */
  linkedAccounts?: IPSEntityV1[]
  /** Linked households */
  linkedContacts?: IPSEntityV1[]
  /** Linked households */
  linkedHouseholds?: IPSEntityV1[]
  /** Lockup terms of the portfolio */
  lockupTerms?: string
  /** Minimum additional investment */
  minAdditionalInvestment?: number
  /** Minimum initial investment */
  minInitialInvestment?: number
  /** Minimum investor level */
  minInvestorLevel?: string
  /** Minimum liquidity */
  minLiquidity?: number
  /** Name of Portfolio */
  name: string
  objectiveScoring?: PortfolioObjectiveScoringV1
  /** The name of the user who owns the portfolio */
  ownerName?: string
  /** Portfolio placeholder fund allocations */
  placeholderFundAllocations?: PortfolioPlaceholderFundAllocationV1[]
  /** Primary objective of the portfolio */
  primaryObjective?: string
  /** Redemption terms of the portfolio */
  redemptionTerms?: string
  /** Risk profile */
  riskProfile?: string
  /** Secondary objective of the portfolio */
  secondaryObjective?: string
  /** Series of the portfolio */
  series?: string
  sharedTeams?: string[]
  sharedUsers?: string[]
  /** Short description of the portfolio */
  shortDescription?: string
  /** Status of the portfolio */
  status?: string
  /** Subscription status of the portfolio */
  subscriptionStatus?: string
  /** Tax reporting (derived from the associated funds if not provided) */
  taxReporting?: string[]
  /** The type of the portfolio - MODEL or CUSTOM_SCRATCH or CUSTOM_REFERENCE_FRAMEWORK_BASED */
  type?: string
  /** Date and time when the portfolio was updated */
  updatedDate?: string
  /** Version of the portfolio */
  version: number
}

export interface SeriesStatV1 {
  /** Date */
  date: string
  /** Value */
  value: number
}

export interface PortfolioDataPointDatesResponseV1 {
  /** Portfolio's data point end date */
  endDate: string
  /** Portfolio's data point start date */
  startDate: string
}

export interface PortfolioSeriesStatResponseV1 {
  /** Request Label */
  label: string
  /** Series of statistics */
  series: SeriesStatV1[]
}

export interface PortfolioAssetClassCorrelationMatrixRequestItemV1 {
  /** Asset class */
  assetClass: string
  /** ID of the index */
  indexId: string
}

export interface PortfolioAssetClassCorrelationMatrixRequestV1 {
  /** End date */
  endDate?: string
  items: PortfolioAssetClassCorrelationMatrixRequestItemV1[]
  /** Start date */
  startDate?: string
}

/**
 * Range of the histogram. If provided overflow and underflow bins will be included
 */
export type PortfolioReturnDistributionRequestV1Range = {
  /** Maximum value of the histogram */
  max: number
  /** Minimum value of the histogram */
  min: number
}

export interface PortfolioReturnDistributionRequestV1 {
  /** End date */
  endDate?: string
  /** Sampling Frequency of the data points counted in the histogram */
  frequency?: string
  items: PortfolioStatsRequestItemV1[]
  /** Number of bins in the histogram */
  numberOfBins: number
  /** Range of the histogram. If provided overflow and underflow bins will be included */
  range?: PortfolioReturnDistributionRequestV1Range
  /** Start date */
  startDate?: string
}

export interface PortfolioDatesRequestV1 {
  items: PortfolioStatsRequestItemV1[]
}

export interface PortfolioDrawdownRequestV1 {
  /** End date */
  endDate?: string
  /** Sampling Frequency of the series */
  frequency?: string
  items: PortfolioStatsRequestItemV1[]
  /** Start date */
  startDate?: string
}

export interface PortfolioReturnsRequestV1 {
  /** End date */
  endDate?: string
  /** Sampling Frequency of the series */
  frequency?: string
  items: PortfolioStatsRequestItemV1[]
  /** Start date */
  startDate?: string
}

export interface PortfolioHoldingPeriodReturnsRequestV1 {
  /** End date */
  endDate?: string
  /** Holding Period frequency valid values MONTHLY,QUARTERLY,ANNUAL */
  holdingPeriod: string
  items: PortfolioStatsRequestItemV1[]
  /** Start date */
  startDate?: string
}

export interface PortfolioGrowthOfRequestV1 {
  /** End date */
  endDate?: string
  /** Sampling Frequency of the series */
  frequency?: string
  items: PortfolioStatsRequestItemV1[]
  /** Principal amount */
  principalAmount: number
  /** Start date */
  startDate?: string
}

export interface SummaryStatV1 {
  /** Name of the statistic */
  name: string
  /** Value of the statistic */
  value?: number
}

export interface PortfolioSummaryStatsResponseV1 {
  analyticsDateRange: AnalyticsDateRangeResponseV1
  /** Sampling Frequency of the underlying data */
  frequency: string
  /** Request Label */
  label: string
  /** Summary statistics */
  stats: SummaryStatV1[]
}

export interface PortfolioWeightsV1 {
  /** Asset class */
  assetClass: string
  /** ID of the product */
  productId: string
  /** Product type (Index/Fund) */
  productType: string
  /** Allocation percentage */
  weight: number
}

export interface PortfolioStatsRequestItemV1 {
  /** Request Label */
  label: string
  /** Asset class allocations */
  portfolioWeights: PortfolioWeightsV1[]
}

export interface HistogramBinV1 {
  /** Number of data points in the bin */
  count: number
  /** Lower bound of the bin */
  lower?: number
  /** Upper bound of the bin */
  upper?: number
}

export interface HistogramV1 {
  analysisDateRange: AnalysisDateRangeV1
  /** Histogram bins */
  bins: HistogramBinV1[]
  /** Request Label */
  label: string
}

export interface PortfolioSummaryStatsRequestItemV1 {
  /** ID of the CMA to be used */
  cmaId?: string
  /** Request Label */
  label: string
  /** Asset class allocations */
  portfolioWeights: PortfolioWeightsV1[]
}

export interface PortfolioSummaryStatsRequestV1 {
  /** Date ranges for the statistics */
  dateRanges: AnalyticsDateRangeRequestV1[]
  /** Portfolio summary statistics request items */
  items: PortfolioSummaryStatsRequestItemV1[]
  /** List of statistics to calculate */
  statistics: string[]
}
