import dayjs from 'dayjs'
import { memo, useEffect, useMemo, useState } from 'react'

import {
  PortfolioListItemV1,
  PortfolioSeriesStatResponseV1,
  useGetActiveSettingsForCurrentUser,
  useGetGrowthOf,
} from '@cais-group/portfolio-construction-tool/domain/api'

import { formatNumberToCurrency } from '../../../../../../../equity/utils/utilitarian'
import {
  formatNumberToCurrencyWithAbbreviation,
  StatisticLabels,
} from '../../../../../../domain/portfolio-construction-tool'
import { modelPortfolios } from '../../../../../../domain/portfolio-construction-tool/types'

import { LineChart } from './line-chart'

export const mapSeriesStatsToLineChartData = ({
  seriesStats,
  currentPortfolioLabel,
  comparedPortfolioLabel,
}: {
  seriesStats: PortfolioSeriesStatResponseV1[]
  currentPortfolioLabel: string
  comparedPortfolioLabel: string
}) => {
  const currentModel = seriesStats.find(
    ({ label }) => label === StatisticLabels.CURRENT_MODEL
  )
  const currentDates = currentModel?.series.map(({ date }) => date) || []
  const comparedModel = seriesStats.find(
    ({ label }) => label === StatisticLabels.COMPARED_MODEL
  )
  const comparedDates = comparedModel?.series.map(({ date }) => date) || []

  return [
    {
      dataKey: currentPortfolioLabel,
      data:
        currentModel?.series.reduce<{ x: string; y: number }[]>(
          (acc, point) => {
            if (!comparedDates.includes(point.date)) {
              return acc
            }

            return acc.concat([
              {
                x: point.date,
                y: point.value,
              },
            ])
          },
          []
        ) || [],
    },
    {
      dataKey: comparedPortfolioLabel,
      data:
        comparedModel?.series.reduce<{ x: string; y: number }[]>(
          (acc, point) => {
            if (!currentDates.includes(point.date)) {
              return acc
            }

            return acc.concat([
              {
                x: point.date,
                y: point.value,
              },
            ])
          },
          []
        ) || [],
    },
  ]
}

export const Growth = memo(
  ({ portfolio }: { portfolio?: PortfolioListItemV1 }) => {
    const [growthCalculations, setGrowthCalculations] = useState<
      PortfolioSeriesStatResponseV1[]
    >([])
    const [dateRange, setDateRange] = useState<[string, string] | undefined>()

    const { mutateAsync, isLoading, isError } = useGetGrowthOf()
    const { data: settings } = useGetActiveSettingsForCurrentUser()
    const comparedPortfolio = modelPortfolios['BALANCED_60_40']

    const allocations = useMemo(
      () => portfolio?.allocations,
      [portfolio?.allocations]
    )

    useEffect(() => {
      if (allocations && settings) {
        setGrowthCalculations([])
        const fetchGrowth = async () => {
          try {
            const response = await mutateAsync({
              data: {
                frequency: 'QUARTERLY',
                startDate: '2000-12-01',
                items: [
                  {
                    portfolioWeights:
                      allocations.map(({ assetClass, allocation }) => ({
                        assetClass: assetClass,
                        weight: Number.isNaN(allocation) ? 0 : allocation,
                        productId:
                          settings?.indexChoices.indexChoices[assetClass],
                        productType: 'IDX',
                      })) || [],
                    label: StatisticLabels.CURRENT_MODEL,
                  },
                  {
                    label: StatisticLabels.COMPARED_MODEL,
                    portfolioWeights: comparedPortfolio.weights.map(
                      ({ assetClass, weight, productId }) => ({
                        assetClass: assetClass,
                        weight: Number.isNaN(weight) ? 0 : weight,
                        productId:
                          productId ||
                          settings?.indexChoices.indexChoices[assetClass],
                        productType: 'IDX',
                      })
                    ),
                  },
                ],
                principalAmount: 1_000_000,
              },
            })
            setGrowthCalculations(response)
            const currentSeries = response?.[0]?.series
            const dateRange = [
              currentSeries[0].date,
              currentSeries[currentSeries.length - 1].date,
            ]
            setDateRange(dateRange as [string, string])
          } catch (error) {
            console.error('Error fetching growth calculations:', error)
          }
        }
        fetchGrowth()
      }
    }, [allocations, mutateAsync, settings, comparedPortfolio])

    const lineChartData = useMemo(
      () =>
        mapSeriesStatsToLineChartData({
          seriesStats: growthCalculations,
          comparedPortfolioLabel: comparedPortfolio.label,
          currentPortfolioLabel: portfolio?.name || '',
        }),
      [growthCalculations, comparedPortfolio.label, portfolio?.name]
    )

    return (
      <div className="flex w-full flex-col pt-24">
        {dateRange ? (
          <div className="flex items-center self-end">
            <div className="small mr-8 text-neutral-600">
              Analysis Period: {dayjs(dateRange[0]).format('MMMM YYYY')} -{' '}
              {dayjs(dateRange[1]).format('MMMM YYYY')}
            </div>
          </div>
        ) : null}
        <LineChart
          yAxisTickFormatter={formatNumberToCurrencyWithAbbreviation}
          xAxisTickFormatter={(value) => dayjs(value).format('YYYY')}
          tooltipValueFormatter={(value) => formatNumberToCurrency({ value })}
          isLoading={isLoading || (!growthCalculations?.length && !isError)}
          dataSets={lineChartData}
        />
      </div>
    )
  }
)

Growth.displayName = 'Growth'
