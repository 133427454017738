import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { ImgHTMLAttributes } from 'react'

import { H6StrongCss } from '../../../../legacy-approved/ui/typography'
import { Issuer } from '../../../util/graphql'

const StyledContainer = styled.div<{
  width?: number
  height?: number
  borderColor?: string | number
  backgroundColor?: string | number
  color?: string | number
  typography?: SerializedStyles
}>`
  width: ${(props) =>
    props.width ? `${props.width}px` : `calc(var(--s56) + var(--s16))`};
  height: ${(props) =>
    props.height ? `${props.height}px` : `calc(var(--s32) + var(--s8))`};
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : `rgb(var(--colors-neutral-200))`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : `rgb(var(--colors-neutral-100))`};
  color: ${(props) =>
    props.color ? props.color : `rgb(var(--colors-neutral-400))`};
  ${(props) => (props.typography ? props.typography : H6StrongCss)};
  box-sizing: content-box;

  img {
    max-height: 100%;
  }
`

export interface ImageOrAcronymProps {
  src?: ImgHTMLAttributes<HTMLImageElement>['src'] | null
  acronym: Issuer['acronym']
  width?: number
  height?: number
  borderColor?: string
  backgroundColor?: string
  color?: string
  typography?: SerializedStyles
  className?: string
}

export function ImageOrAcronym(props: ImageOrAcronymProps) {
  const {
    src,
    acronym,
    width,
    height,
    borderColor,
    color,
    backgroundColor,
    typography,
    className,
  } = props
  return (
    <StyledContainer
      color={color}
      borderColor={borderColor}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      typography={typography}
      className={className}
    >
      {src ? <img src={src} alt={acronym} /> : acronym}
    </StyledContainer>
  )
}
