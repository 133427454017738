import type { AnchorHTMLAttributes } from 'react'

import { Link, LinkProps } from '../../link'

import { Adornment } from './shared/adornment'
import { buttonStyles } from './shared/button-styles'
import type { ComponentProps } from './shared/types'

export type ButtonLinkProps = ComponentProps<
  AnchorHTMLAttributes<HTMLAnchorElement>
> &
  LinkProps

export const ButtonLink = ({
  href,
  variant = 'primary',
  size = 'regular',
  color = 'primary',
  children,
  grow = false,
  inverse = false,
  endAdornment,
  startAdornment,
  ...rest
}: ButtonLinkProps) => {
  const { className } = buttonStyles({
    color,
    inverse,
    disabled: false,
    grow,
    loading: false,
    size,
    variant,
  })

  return (
    <Link href={href || ''} className={className} {...rest}>
      <Adornment adornment={startAdornment} size={size} />
      {children}
      <Adornment adornment={endAdornment} size={size} />
    </Link>
  )
}
