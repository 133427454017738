import { Components } from '@mui/material'

export const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      '.MuiFormLabel-asterisk': {
        color: 'rgb(var(--colors-error-600))',
      },
    },
  },
}
